import {
  ComponentPropsWithoutRef,
  ComponentRef,
  ComponentType,
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import Button from '@/components/atoms/Button';
import ButtonContainer from '@/components/atoms/ButtonContainer';
import HorizontalRule from '@/components/atoms/HorizontalRule';
import SidebarTemplate from '@/components/templates/SidebarTemplate';
import styles from '@/components/organisms/EditYourCostsSidebar.module.scss';
import MaximumVehiclePaymentField from '@/components/organisms/MaximumVehiclePaymentField';
import AnnualMileageField from '@/components/organisms/AnnualMileageField';
import CostOfDieselField from '@/components/organisms/CostOfDieselField';
import CostOfPetrolField from '@/components/organisms/CostOfPetrolField';
import CostOfElctricityField from '@/components/organisms/CostOfElectricityField';
import Heading from '@/components/atoms/Heading';
import ContractLengthField from '@/components/organisms/ContractLengthField';
import { useQuestionnaireForm } from '@/providers/QuestionnaireFormProvider';

type Element = ComponentRef<typeof SidebarTemplate>;

type Props = ComponentPropsWithoutRef<typeof SidebarTemplate> & {
  ref: ForwardedRef<Element>;
  onApply?: () => void;
};

const EditYourCostsSidebarBase: ComponentType<Props> =
  function EditYourCostsSidebar({ ref, onApply, onChange, ...props }) {
    const sidebar = useRef<ComponentRef<typeof SidebarTemplate>>(null);
    useImperativeHandle(
      ref,
      () => ({
        close: (force?: boolean) => {
          sidebar.current?.close(force);
        },
        open: () => {
          sidebar.current?.open();
        },
      }),
      [],
    );

    const [, setOpen] = useState(Boolean(props.openByDefault));
    const handleChange = useCallback<NonNullable<typeof onChange>>(
      (open) => {
        setOpen(open);
        onChange?.(open);
      },
      [onChange],
    );

    const { form } = useQuestionnaireForm([
      'annualMileage',
      'maximumVehiclePayment',
      'contractLength',
      'costOfDiesel',
      'costOfElectricity',
      'costOfPetrol',
    ]);

    const handleConfirm = useCallback(async () => {
      const errors = await form.validateForm();
      if (Object.keys(errors).length !== 0) {
        console.warn('Validation failed', { errors });
        return;
      }
      await form.submitForm();
      sidebar.current?.close(true);
      onApply?.();
    }, [form, onApply]);

    return (
      <SidebarTemplate
        id="sidebar-costedit"
        {...props}
        ref={sidebar}
        onChange={handleChange}
        title="Edit your costs">
        <div className={styles.content}>
          <Heading level={3} title="Your budget and term" />
          <Heading level={4} title="What is your maximum vehicle payment?" />
          <MaximumVehiclePaymentField />
          <Heading level={4} title="How long do you plan owning the car?" />
          <ContractLengthField />
          <HorizontalRule />
          <Heading level={3} title="Your mileage and fuel/energy costs" />
          <Heading level={4} title="How many miles do you drive a year?" />
          <AnnualMileageField />
          <Heading level={4} title="Adjust to your local fuel/energy costs" />
          <CostOfPetrolField />
          <CostOfDieselField />
          <CostOfElctricityField />
        </div>
        <div className={styles.actions}>
          <HorizontalRule />
          <ButtonContainer vertical stretch>
            <Button onClick={handleConfirm}>Confirm</Button>
            <Button secondary onClick={() => sidebar.current?.close()}>
              Cancel
            </Button>
          </ButtonContainer>
        </div>
      </SidebarTemplate>
    );
  };

const EditYourCostsSidebar: typeof EditYourCostsSidebarBase =
  // eslint-disable-next-line react/display-name
  forwardRef<Element, Props>((props, ref) =>
    EditYourCostsSidebarBase({ ...props, ref }),
  );

export default EditYourCostsSidebar;
