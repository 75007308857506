import { ComponentType, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';

import NumberSelect from '@/components/molecules/NumberSelect';
import { useFormatNumber } from '@/utils/translation';
import { useFetchConfigurationQuery } from '@/services/fes';

const AnnualMileageField: ComponentType = function AnnualMileageField() {
  const { t } = useTranslation();
  const formatNumber = useFormatNumber();

  /** @todo get these dynamically from the api */
  const possibleValues = useRef([5, 10, 15, 20, 25, 30]).current;

  const [
    mileage,
    { error: mileageError },
    { setTouched: setMileageTouched, setValue: setMileage },
  ] = useField<string>('annualMileage');

  const items = useMemo(
    () =>
      possibleValues.map((key) => ({
        key,
        label: formatNumber(key * 1000),
      })),
    [formatNumber, possibleValues],
  );

  const configuration = useFetchConfigurationQuery();
  const { data: { localisation } = {} } = configuration;

  const defaultValue =
    mileage.value ||
    configuration.currentData?.averages?.mileage ||
    possibleValues[0];

  return (
    <NumberSelect
      hint={localisation?.pages.journey.annualMileage.hint}
      labelText={`${t('shared.inputs.annual_mileage.label')}`}
      tooltip={localisation?.pages.journey.annualMileage.tooltip}
      defaultValue={defaultValue}
      error={mileageError}
      items={items}
      onChange={(e) => {
        setMileageTouched(true);
        setMileage(e.target.value);
      }}
    />
  );
};

export default AnnualMileageField;
