import { useCallback, useEffect, useRef, useState } from 'react';

function useDebouncedState<T>(initialValue: T, ms: number) {
  const [value, setValueInternal] = useState(initialValue);

  const timeout = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
  }, []);

  const setValueNow: typeof setValueInternal = useCallback((args) => {
    if (timeout.current) clearTimeout(timeout.current);
    setValueInternal(args);
  }, []);

  const setValueDebounced: typeof setValueNow = useCallback(
    (args) => {
      if (timeout.current) clearTimeout(timeout.current);
      timeout.current = setTimeout(() => setValueNow(args), ms);
    },
    [ms, setValueNow],
  );

  return [value, setValueDebounced, setValueNow] as const;
}

export default useDebouncedState;
