/* eslint-disable no-case-declarations */
import { useFormik } from 'formik';
import { useMemo } from 'react';

import {
  QuestionnaireContextState,
  useQuestionnaireContext,
} from '@/providers/QuestionnaireContextProvider';
import { useFetchConfigurationQuery } from '@/services/fes/configuration';
import { BodyStyleOrUnknown } from '@/services/fes';

export const useQuestionnaireForm = (
  enabledFields: (keyof QuestionnaireContextState['fields'])[] = [],
) => {
  const [state, dispatch] = useQuestionnaireContext();

  const configuration = useFetchConfigurationQuery();
  const { data: { localisation } = {} } = configuration;
  const minValue = localisation?.pages.journey.financeBudget.minValue || 300;
  const maxValue = localisation?.pages.journey.financeBudget.maxValue || 1000;

  const initialValues = useMemo(() => {
    const values = {
      annualMileage: state.fields.annualMileage || '',
      bodyStyles: state.fields.bodyStyles || '',
      contractLength: `${state.fields.contractLength || ''}`,
      costOfDiesel: `${configuration.data?.averages?.diesel.value ?? 0}`,
      costOfElectricity: `${
        configuration.data?.averages?.electricity.value ?? 0
      }`,
      costOfPetrol: `${configuration.data?.averages?.petrol.value ?? 0}`,
      isSubmitted: state.fields.isSubmitted,
      maximumVehiclePayment: `${state.fields.maximumVehiclePayment || ''}`,
    };
    return Object.fromEntries(
      Object.entries(values).filter(
        ([key]) =>
          enabledFields.length === 0 ||
          (enabledFields as string[]).includes(key),
      ),
    ) as Partial<typeof values>;
  }, [
    configuration.data?.averages?.diesel.value,
    configuration.data?.averages?.electricity.value,
    configuration.data?.averages?.petrol.value,
    enabledFields,
    state.fields.annualMileage,
    state.fields.bodyStyles,
    state.fields.contractLength,
    state.fields.isSubmitted,
    state.fields.maximumVehiclePayment,
  ]);

  const form = useFormik<typeof initialValues>({
    initialValues,
    onSubmit: (values) => {
      dispatch({
        type: 'SET_FIELDS',
        fields: {
          ...values,
          bodyStyles: (values.bodyStyles || []) as BodyStyleOrUnknown[],
          annualMileage: parseInt(`${values.annualMileage ?? 10}`, 10),
          contractLength: parseInt(values.contractLength || '0', 10),
          costOfDiesel: parseFloat(values.costOfDiesel || '0'),
          costOfElectricity: parseFloat(values.costOfElectricity || '0'),
          costOfPetrol: parseFloat(values.costOfPetrol || '0'),
          maximumVehiclePayment: parseFloat(values.maximumVehiclePayment || ''),
        },
      });
    },
    validate: (values) => {
      const fields = Object.keys(values) as (keyof typeof initialValues)[];

      return Object.fromEntries(
        fields
          .map((field) => {
            // eslint-disable-next-line default-case
            switch (field) {
              case 'maximumVehiclePayment':
                const paymentStr = values[field];
                if (!paymentStr) {
                  return [field, ['Required.']];
                }
                const payment = parseFloat(paymentStr);
                if (
                  Number.isNaN(payment) ||
                  Boolean(paymentStr.match(/^-?\d*(\.\d*)?$/)) === false ||
                  payment < minValue ||
                  payment > maxValue ||
                  payment % 50 !== 0
                ) {
                  return [
                    field,
                    [
                      `Must be a valid number between ${minValue} and ${maxValue} with steps of 50.`,
                    ],
                  ];
                }
                return [field, []];
              case 'contractLength':
                if (values[field] === '') {
                  return [field, ['Please choose a contract length.']];
                }
                return [field, []];
              case 'annualMileage':
                return [field, []];
              case 'isSubmitted':
                return [field, []];
              case 'costOfDiesel': // should-fall-through
              case 'costOfPetrol': // should-fall-through
              case 'costOfElectricity':
                const costStr = values[field];
                if (costStr === undefined || values[field] === '') {
                  return [field, ['Must be a valid number between 0 and 500.']];
                }
                const cost = parseFloat(costStr);
                if (
                  Number.isNaN(cost) ||
                  Boolean(costStr.match(/^-?\d*(\.\d*)?$/)) === false ||
                  cost < 0 ||
                  cost > 500
                ) {
                  return [field, ['Must be a valid number between 0 and 500.']];
                }
                return [field, []];
              case 'bodyStyles':
                const styles = values[field];
                if (styles === undefined || styles.length === 0) {
                  return [field, ['Please choose at least one option.']];
                }
                return [field, []];
            }
            throw new Error(`Unexpected field received: ${field}`);
          })
          .filter((current) => current[1].length !== 0),
      );
    },
  });

  return { form };
};
