type ValueWithUnit = {
  value: number;
  unit: string;
};

export const Fuels = ['diesel', 'electricity', 'petrol'];
export type Fuel = typeof Fuels[number];

export const BodyStyles = [
  'coupe',
  'estate',
  'hatchback',
  'mpv',
  'saloon',
  'suv',
  'van',
] as const;
export type BodyStyle = typeof BodyStyles[number];
export type BodyStyleOrUnknown = BodyStyle | 'unknown';
export const BodyStylesWithUnknown: readonly BodyStyleOrUnknown[] = [
  ...BodyStyles,
  'unknown',
];

export const Manufacturers = [
  'audi',
  'cupra',
  'seat',
  'skoda',
  'vw', // Volkswagen
  'vwcv', // Volkswagen Commercial Vehicles
  'vwpc', // Volkswagen Passenger Cars
] as const;
export type Manufacturer = typeof Manufacturers[number];

export const MonthlyExpenseCategories = [
  'vehiclePayment',
  'fuel',
  'serviceAndMaintenance',
  'roadTax',
] as const;
export type MonthlyExpenseCategory = typeof MonthlyExpenseCategories[number];

export type Vehicle = {
  annualMileage: number;
  name: string;
  manufacturer: Manufacturer;
  performance: string;
  type: VehicleType;
  orderKey: string;
  bodyStyle: BodyStyle;
  emission: ValueWithUnit;
  imageURL?: string;
  learnMore: string;
  contractLength: number;
  costs: {
    monthly: Record<MonthlyExpenseCategory, number>;
    totalMonthlyPayment: number;
    deposit: number;
    finalPayment: number;
    apr: ValueWithUnit;
  };
};

export const VehicleTypes = ['electric', 'hybrid', 'petrol', 'diesel'] as const;
export type VehicleType = typeof VehicleTypes[number];
