import {
  forwardRef,
  HTMLProps,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import Spinner from '@/components/atoms/Spinner';
import styles from '@/components/templates/LoadableView.module.scss';
import useDebouncedState from '@/hooks/useDebouncedState';
import useDebouncedValue from '@/hooks/useDebouncedValue';

export type LoadableViewElement = {
  simulateRefetch: () => void;
};

type Props = Omit<HTMLProps<HTMLDivElement>, 'ref'> & {
  isLoading: boolean;
  isFetching: boolean;
};

const LoadableView = forwardRef<LoadableViewElement, Props>(
  function LoadableView(
    { isLoading, isFetching, className, children, ...props },
    ref,
  ) {
    const isLoaded = useRef(false);
    const isLoadingDebounced = useDebouncedValue(isLoading, 750);
    useEffect(() => {
      if (isLoadingDebounced) return;
      isLoaded.current = true;
    }, [isLoadingDebounced]);

    const [isRefetched, setRefetched] = useState(false);
    const [isRefetching, setRefetchingDebounced, setRefetchingNow] =
      useDebouncedState(false, 750);
    useEffect(() => {
      if (isLoaded.current === false) return;
      if (isFetching === isRefetching) return;
      if (isFetching === true) {
        setRefetchingNow(true);
      } else {
        setRefetched(true);
        setRefetchingDebounced(false);
      }
    }, [isFetching, isRefetching, setRefetchingDebounced, setRefetchingNow]);

    useImperativeHandle(
      ref,
      () => ({
        simulateRefetch: () => setRefetchingNow(true),
      }),
      [setRefetchingNow],
    );

    useEffect(() => {
      if (!isRefetching) return;
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, [isRefetching]);

    return (
      <>
        {isRefetching && <Spinner center busy fullPage />}
        <div
          {...props}
          className={[
            className,
            isRefetching && styles.refetching,
            isRefetched && !isRefetching && styles.refetched,
          ].join(' ')}>
          {isLoadingDebounced ? <Spinner center /> : children}
        </div>
      </>
    );
  },
);

export default LoadableView;
