import { ComponentType } from 'react';
import { Combobox as BronsonCombobox } from '@vwfs-bronson/bronson-react';

type Item = {
  key: string;
  value: string;
};

const Combobox: ComponentType<{
  id?: string;
  name?: string;
  placeholder?: string;
  defaultValue?: string[];
  onChange?: (items: Item[]) => void;
  multiple?: boolean;
  items: Item[];
}> = function Combobox({ defaultValue, items, ...props }) {
  return (
    <BronsonCombobox
      defaultState={{
        value: items.filter(({ key }) => defaultValue?.includes(key)),
      }}
      {...props}
      state={{ value: ['coupe'] }}
      children={items?.map(({ value, key }) => (
        <BronsonCombobox.Item
          key={key}
          optionKey={key}
          optionValue={value}
          children={value}
        />
      ))}
    />
  );
};

export default Combobox;
