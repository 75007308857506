import { ComponentType, ReactNode } from 'react';
import { MediaTeaser as BronsonMediaTeaser } from '@vwfs-bronson/bronson-react';
import classnames from 'classnames';

import styles from '@/components/molecules/MediaTeaser.module.scss';

type MediaTeaserProps = {
  buttonLabel?: string;
  children?: ReactNode;
  imgProps?: {
    alt?: string;
    sizes?: string;
    src: string;
    srcSet?: string;
  };
  onButtonClick?: () => void;
  title?: string;
  reversed?: boolean;
  fill?: boolean;
};

const MediaTeaser: ComponentType<MediaTeaserProps> = function MediaTeaser({
  fill = false,
  ...props
}) {
  return (
    <div className={classnames([fill && styles.fill])}>
      <BronsonMediaTeaser {...props} />
    </div>
  );
};

export default MediaTeaser;
