import {
  Brand,
  BronsonTheme,
  bronsonThemes,
  bronsonThemeToBrand,
} from '@/config/constants';

type Config = {
  brand: Brand;
  bronsonTheme: string;
  environment: string;
  fesBaseUrl: string;
  releaseDate: string;
  version: string;
};
function getConfig(): Config {
  const bronsonTheme = getBronsonTheme();

  return {
    brand: bronsonThemeToBrand[bronsonTheme],
    bronsonTheme,
    environment: process.env.REACT_APP_ENVIRONMENT || 'dev',
    fesBaseUrl: getFesBaseUrl(),
    releaseDate: process.env.REACT_APP_RELEASE_DATE || '-',
    version: process.env.REACT_APP_VERSION || '-',
  };
}

function getBronsonTheme(): BronsonTheme {
  const theme = process.env.REACT_APP_BRONSON_THEME as BronsonTheme;
  if (!bronsonThemes.includes(theme)) {
    throw new Error(`REACT_APP_BRONSON_THEME="${theme}" is invalid.`);
  }
  return theme;
}

function getFesBaseUrl(): string {
  const url = process.env.REACT_APP_FES_BASE_URL as string;
  if (!url) {
    throw new Error(`REACT_APP_FES_BASE_URL must be set`);
  }
  return url;
}

export default getConfig();
