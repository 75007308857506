import { ButtonGroup as BronsonButtonGroup } from '@vwfs-bronson/bronson-react';
import { ComponentPropsWithoutRef, ReactNode } from 'react';

type Props<T> = Pick<
  ComponentPropsWithoutRef<typeof BronsonButtonGroup>,
  'onChange'
> & {
  auto?: boolean;
  buttonGroupType: 'radio' | 'checkbox';
  buttonGroupName: string;
  items: ItemProps<T>[];
  errorMessage?: string;
  value?: string;
};

type ItemProps<T> = {
  key: T;
  label: ReactNode;
};

const ButtonGroup = function ButtonGroup<T>({
  items,
  onChange,
  value,
  ...props
}: Props<T>) {
  return (
    <BronsonButtonGroup {...props}>
      {items.map(({ key, label }, index) => (
        <BronsonButtonGroup.Item
          key={key}
          index={index.toString()}
          children={label}
          value={key}
          onChange={onChange}
          defaultChecked={String(value) === String(key)}
        />
      ))}
    </BronsonButtonGroup>
  );
};

export default ButtonGroup;
