import { ProductTable as BronsonProductTable } from '@vwfs-bronson/bronson-react';
import { ComponentType, ReactNode } from 'react';

type Props = {
  title: string;
  children?: ReactNode;
  headingLevel: number;
};

const ProductTable: ComponentType<Props> = function ProductTable(props) {
  return <BronsonProductTable {...props} />;
};

export default ProductTable;
