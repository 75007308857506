import {
  ComponentPropsWithoutRef,
  ComponentRef,
  ComponentType,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useField } from 'formik';

import Button from '@/components/atoms/Button';
import ButtonContainer from '@/components/atoms/ButtonContainer';
import HorizontalRule from '@/components/atoms/HorizontalRule';
import BodyStyleSelector from '@/components/molecules/BodyStyleSelector';
import SidebarTemplate from '@/components/templates/SidebarTemplate';
import styles from '@/components/organisms/BodyStyleSelectorSidebar.module.scss';
import { BodyStyleOrUnknown } from '@/services/fes/types';

type Element = ComponentRef<typeof SidebarTemplate>;

type Props = ComponentPropsWithoutRef<typeof SidebarTemplate> & {
  ref: ForwardedRef<Element>;
  onSubmitForm: () => void;
};

const BodyStyleSelectorSidebarBase: ComponentType<Props> =
  function BodyStyleSelectorSidebar({ ref, onSubmitForm, onChange, ...props }) {
    const sidebar = useRef<ComponentRef<typeof SidebarTemplate>>(null);
    useImperativeHandle(
      ref,
      () => ({
        close: (force?: boolean) => {
          sidebar.current?.close(force);
        },
        open: () => {
          sidebar.current?.open();
        },
      }),
      [],
    );

    const [, setOpen] = useState(Boolean(props.openByDefault));
    const handleChange = useCallback<NonNullable<typeof onChange>>(
      (open) => {
        setOpen(open);
        onChange?.(open);
      },
      [onChange],
    );

    const [{ value: bodyStyles }, , { setValue }] =
      useField<BodyStyleOrUnknown[]>('bodyStyles');

    const [tempBodyStyles, setTempBodyStyles] =
      useState<BodyStyleOrUnknown[]>(bodyStyles);

    useEffect(() => {
      setTempBodyStyles(bodyStyles);
    }, [bodyStyles]);

    const onBodyStyleChange = (newBodyStyles: BodyStyleOrUnknown[]) => {
      if (!newBodyStyles.length) return;

      setTempBodyStyles(newBodyStyles);
    };

    const handleConfirm = useCallback(async () => {
      setValue(tempBodyStyles as BodyStyleOrUnknown[]);
      onSubmitForm();
      sidebar.current?.close(true);
    }, [onSubmitForm, setValue, tempBodyStyles]);

    return (
      <SidebarTemplate
        id="sidebar-bodyselect"
        {...props}
        ref={sidebar}
        onChange={handleChange}
        title="What type of vehicle are you looking to own?"
        description="Choose the vehicle body styles that best suit your needs.">
        <BodyStyleSelector
          small
          nonEmpty
          onBodyStyleChange={onBodyStyleChange}
          values={tempBodyStyles}
        />
        <div className={styles.actions}>
          <HorizontalRule />
          <ButtonContainer vertical stretch>
            <Button primary onClick={handleConfirm}>
              Confirm
            </Button>
            <Button secondary onClick={() => sidebar.current?.close()}>
              Cancel
            </Button>
          </ButtonContainer>
        </div>
      </SidebarTemplate>
    );
  };

const BodyStyleSelectorSidebar: typeof BodyStyleSelectorSidebarBase =
  // eslint-disable-next-line react/display-name
  forwardRef<Element, Props>((props, ref) =>
    BodyStyleSelectorSidebarBase({ ...props, ref }),
  );

export default BodyStyleSelectorSidebar;
