import BudgetScreen from '@/screens/Questionnaire/BudgetScreen';
import CostsScreen from '@/screens/Questionnaire/CostsScreen';
import MileageScreen from '@/screens/Questionnaire/MileageScreen';
import StyleScreen from '@/screens/Questionnaire/StyleScreen';

export default {
  BudgetScreen,
  CostsScreen,
  MileageScreen,
  StyleScreen,
};
