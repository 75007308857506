import { ChangeEvent, ComponentType } from 'react';
import { Input as BronsonInput } from '@vwfs-bronson/bronson-react';

import styles from '@/components/atoms/Input.module.scss';

type Props = {
  id?: string;
  placeholder?: string;
  defaultValue?: string;
  error?: boolean;
  value?: string;
  addonText?: string;
  reversed?: boolean;
  onChange?: (v: ChangeEvent<HTMLInputElement>) => void;
};

const Input: ComponentType<Props> = function Input(props) {
  return <BronsonInput {...props} className={styles.addonText} />;
};

export default Input;
