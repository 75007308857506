import { ComponentType, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import FormHeading from '@/components/atoms/FormHeading';
import StackedBarMolecule from '@/components/molecules/StackedBarMolecule';
import styles from '@/components/organisms/CostBreakdown.module.scss';
import {
  MonthlyExpenseCategories,
  MonthlyExpenseCategory,
  Vehicle,
} from '@/services/fes/types';
import { useFormatCurrency } from '@/utils/translation';
import { useFetchConfigurationQuery } from '@/services/fes';

type Props = {
  vehicles?: Vehicle[];
  vehicle: Vehicle;
  maximums?: Record<MonthlyExpenseCategory, number>;
};

const CostBreakdown: ComponentType<Props> = function CostBreakdown({
  vehicles = [],
  vehicle,
  maximums: customMaximums,
}) {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const maximums = useMemo(() => {
    if (customMaximums) {
      return customMaximums;
    }
    return MonthlyExpenseCategories.reduce<
      Record<MonthlyExpenseCategory, number>
    >(
      (carry, category) => ({
        ...carry,
        [category]: [vehicle, ...vehicles].reduce(
          (maximumMonthlyExpense, currentVehicle) =>
            Math.max(
              maximumMonthlyExpense,
              currentVehicle.costs.monthly[category],
            ),
          0,
        ),
      }),
      {
        fuel: 0,
        roadTax: 0,
        serviceAndMaintenance: 0,
        vehiclePayment: 0,
      },
    );
  }, [customMaximums, vehicle, vehicles]);

  return (
    <div className={styles.container}>
      {MonthlyExpenseCategories.map((category) => (
        <CostBreakdownItem
          key={category}
          category={category}
          max={maximums[category]}
          value={vehicle.costs.monthly[category]}
          description={(() => {
            switch (category) {
              case 'vehiclePayment':
                return `${t('shared.vehicle_payment_compliance', {
                  deposit: formatCurrency(vehicle.costs.deposit),
                  final_payment: formatCurrency(vehicle.costs.finalPayment),
                  apr: vehicle.costs.apr.value,
                })}`;
              default:
                return '';
            }
          })()}
        />
      ))}
    </div>
  );
};

const CostBreakdownItem: ComponentType<{
  category: MonthlyExpenseCategory;
  max: number;
  value: number;
  description?: string;
}> = function CostBreakdownItem({ category, description, max, value }) {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const { currentData: configuration } = useFetchConfigurationQuery();
  const tooltip = configuration?.localisation.results[category] || '';

  return (
    <div>
      <FormHeading
        title={t(`shared.categories.${category}.title`)}
        tooltip={tooltip}
      />
      <StackedBarMolecule
        max={max}
        values={[
          {
            color: `var(--stacked-bar-item-${category}-color)`,
            value,
          },
        ]}
        description={description}
        showTotal
        formatValue={formatCurrency}
      />
    </div>
  );
};

export default CostBreakdown;
