import { useEffect } from 'react';

import { useTrackingContext } from '@/providers/TrackingProvider';
import styles from '@/screens/ServerErrorScreen.module.scss';

const ServerErrorScreen = function ServerErrorScreen() {
  const [, track] = useTrackingContext();

  useEffect(() => {
    track({
      type: 'view',
      trackingStateUpdate: {
        core: {
          pageInfo: {
            pageName: 'Technical Error',
          },
          attributes: {
            viewChange: 'Technical Error',
          },
        },
        eventInfo: [
          {},
          {
            eventType: 'Error',
            eventAction: 'Technical error',
          },
        ],
        error: {
          errorCode: '500',
          errorMessage: 'Technical error',
        },
      },
    });
  }, [track]);

  return (
    <div className={styles.container}>
      <span className="c-hero-teaser__title-row">
        500 - internal server error
      </span>
    </div>
  );
};

export default ServerErrorScreen;
