import { ComponentType } from 'react';
import { Footer as BronsonFooter } from '@vwfs-bronson/bronson-react';

import { useFetchConfigurationQuery } from '@/services/fes/configuration';

type FooterProps = Record<string, unknown>;

const footerText = (
  <>
    Volkswagen Financial Services (United Kingdom) Limited (VWFS) - company
    registration number 2835230, VAT registration number GB 745 9638 83 is a
    wholly owned subsidiary of Volkswagen Financial Servces AG, whose registered
    office is at Yeomans Drive, Blakelands, Milton Keynes, MK14 5LR, a company
    authorised to conduct business in the United Kingdom.
    <br />
    <br />
    Volkswagen Financial Services (UK) Limited is authorised and regulated by
    the Financial Conduct Authority (FCA) under registration number 311988. You
    can check this on the Financial Services Register by visiting{' '}
    <a href="https://www.fca.org.uk" target="_blank" rel="noreferrer">
      www.fca.org.uk
    </a>
    .
  </>
);

const metaNavItems: {
  href: string;
  label: string;
}[] = [
  {
    href: 'https://customer.vwfs.co.uk/cookies-policy.html ',
    label: 'Cookie Policy',
  },
  {
    href: 'https://customer.vwfs.co.uk/legal-notice.html',
    label: 'Legal Terms',
  },
  {
    href: 'https://customer.vwfs.co.uk/modern-slavery-statement.html',
    label: 'Modern Slavery Statement',
  },
  {
    href: 'https://customer.vwfs.co.uk/privacy-policy.html  ',
    label: 'Privacy Policy',
  },
  {
    href: '/third-party-licenses',
    label: 'Third Party Licenses',
  },
];

const Footer: ComponentType<FooterProps> = function Footer() {
  const configuration = useFetchConfigurationQuery();
  const { data: { localisation } = {} } = configuration;
  return (
    <BronsonFooter>
      <BronsonFooter.ModuleMetaNav copyrightText={localisation?.copyright}>
        {metaNavItems.map(({ href, label }) => {
          const isLink = !href.startsWith('/', 0);
          return (
            <BronsonFooter.ModuleMetaNav.Item
              key={href}
              href={href}
              normalLink={isLink}
              linkProps={{ target: isLink ? '_blank' : '' }}>
              {label}
            </BronsonFooter.ModuleMetaNav.Item>
          );
        })}
      </BronsonFooter.ModuleMetaNav>
      <BronsonFooter.ModuleFootnotes children={footerText} />
    </BronsonFooter>
  );
};

export default Footer;
