import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import config from '@/config';

const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: config.fesBaseUrl,
  }),
  {
    retryCondition: () => false,
  },
);

export const api = createApi({
  baseQuery,
  endpoints: () => ({}),
  tagTypes: ['Configuration'],
});
