import { Card as BronsonCard } from '@vwfs-bronson/bronson-react';
import { ComponentType, ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  className?: string;
  button?: ReactNode;
  footer?: boolean;
  subtitle?: ReactNode;
  title?: ReactNode;
  element?: string;
  icon?: string;
  additionalInfo?: ReactNode;
};

const Card: ComponentType<Props> = function Card({
  children,
  element = 'article',
  ...props
}) {
  return (
    <BronsonCard element={element} {...props}>
      {children}
    </BronsonCard>
  );
};

export default Card;
