import Heading from '@/components/atoms/Heading';
import AnnualMileageField from '@/components/organisms/AnnualMileageField';
import { useFetchConfigurationQuery } from '@/services/fes';

const AnnualMileageScreen = function AnnualMileageScreen() {
  const configuration = useFetchConfigurationQuery();
  const { data: { localisation } = {} } = configuration;

  return (
    <>
      <Heading
        level={2}
        title={localisation?.pages?.journey?.annualMileage?.header}
      />
      <AnnualMileageField />
    </>
  );
};

export default AnnualMileageScreen;
