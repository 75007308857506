import { ComponentType, useMemo } from 'react';

import Heading from '@/components/atoms/Heading';
import styles from '@/screens/ThirdPartyPolicyScreen.module.scss';
import SectionHeading from '@/components/atoms/SectionHeading';
import DependecyTable, {
  Dependency,
} from '@/components/molecules/DependecyTable';
import licenseData from '@/license-types.json';
import rawLicenses from '@/licenses.json';

type License = {
  license: string;
  dependencies: Dependency[];
  source: string;
  description: string;
};

const ThirdPartyPolicyScreen: ComponentType =
  function ThirdPartyPolicyScreen() {
    const licenses = useMemo(getLicenses, []);
    return (
      <div className={styles.container}>
        <Heading title="Third Party Licenses" />
        {licenses.map((license) => (
          <section key={license.license} className={styles.section}>
            <SectionHeading title={license.license} />
            <DependecyTable dependencies={license.dependencies} />
            <p>
              {'Source: '}
              <a href={license.source} target="_blank" rel="noreferrer">
                {license.source}
              </a>
            </p>
            <p className={styles.description}>{license.description}</p>
          </section>
        ))}
      </div>
    );
  };

const getLicenses = (): License[] => {
  const licenses = rawLicenses.map((entry) => ({
    name: entry.name,
    version: entry.installedVersion,
    copyright: entry.author,
    license: entry.licenseType,
  }));

  const licenseTypes = Array.from(
    new Set(licenseData.map((entry) => entry.key)),
  );

  return licenseTypes.map((key) => {
    const licenseEntry = licenseData.find((element) => element.key === key);
    if (!licenseEntry) throw new Error(`Invalid license entry: ${key}`);
    return {
      license: licenseEntry.license,
      dependencies: licenses
        .filter((entry) => {
          if (entry.license === key) return entry;
          return undefined;
        })
        .map((entry) => ({
          name: entry.name,
          version: entry.version,
          copyright: entry.copyright,
        })),
      source: licenseEntry.source,
      description: licenseEntry.description,
    };
  });
};

export default ThirdPartyPolicyScreen;
