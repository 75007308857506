import { ContentSection } from '@vwfs-bronson/bronson-react';
import { Outlet, useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';

import Footer from '@/components/organisms/Footer';
import Header from '@/components/organisms/Header';
import styles from '@/components/templates/BaseTemplate.module.scss';
import usePrevious from '@/hooks/usePrevious';

const BaseTemplate = function BaseTemplate() {
  const contentRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname, { setInitialValue: true });
  useEffect(() => {
    if (pathname === prevPathname) return;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }, [pathname, prevPathname]);

  return (
    <div className={styles.container}>
      <div className={styles.header} children={<Header />} />
      <div ref={contentRef} className={styles.content}>
        <ContentSection pageWrapSize="medium">
          <Outlet />
        </ContentSection>
      </div>
      <div className={styles.footer} children={<Footer />} />
    </div>
  );
};

export default BaseTemplate;
