import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import '@/index.scss';
import '@/i18n';
import { TrackingProvider } from '@/providers/TrackingProvider';
import { store } from '@/store';
import reportWebVitals from '@/reportWebVitals';
import RouterProvider from '@/providers/RouterProvider';
import QuestionnaireProvider from '@/providers/QuestionnaireContextProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <TrackingProvider>
        <QuestionnaireProvider>
          <RouterProvider />
        </QuestionnaireProvider>
      </TrackingProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
