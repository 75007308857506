import {
  ComponentPropsWithoutRef,
  ComponentRef,
  ComponentType,
  ForwardedRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button';
import HorizontalRule from '@/components/atoms/HorizontalRule';
import SidebarTemplate from '@/components/templates/SidebarTemplate';
import styles from '@/components/organisms/ChangeVehicleSidebar.module.scss';
import {
  BodyStyle,
  useQuestionnaireVehicles,
  Vehicle,
  VehicleType,
} from '@/services/fes';
import Card from '@/components/atoms/Card';
import Heading from '@/components/atoms/Heading';
import { useFormatCurrency, useFormatNumber } from '@/utils/translation';
import { useQuestionnaireContext } from '@/providers/QuestionnaireContextProvider';
import BodyStyleCombobox from '@/components/molecules/BodyStyleCombobox';

type Element = ComponentRef<typeof SidebarTemplate>;

type Props = ComponentPropsWithoutRef<typeof SidebarTemplate> & {
  ref: ForwardedRef<Element>;
  vehicleType?: VehicleType;
};

const ChangeVehicleSidebarBase: ComponentType<Props> =
  function EditYourCostsSidebar({ ref, vehicleType, onChange, ...props }) {
    const sidebar = useRef<ComponentRef<typeof SidebarTemplate>>(null);
    useImperativeHandle(
      ref,
      () => ({
        close: (force?: boolean) => {
          sidebar.current?.close(force);
        },
        open: () => {
          sidebar.current?.open();
        },
      }),
      [],
    );

    const [, setOpen] = useState(Boolean(props.openByDefault));
    const handleChange = useCallback<NonNullable<typeof onChange>>(
      (open) => {
        setOpen(open);
        onChange?.(open);
      },
      [onChange],
    );

    const [, dispatch] = useQuestionnaireContext();
    const [bodyStyles, setBodyStyles] = useState<BodyStyle[]>([]);
    const { data: vehicles } = useQuestionnaireVehicles();
    const displayedVehicles = useMemo(
      () =>
        vehicles?.filter(
          (current) =>
            (!vehicleType || current.type === vehicleType) &&
            (bodyStyles.length === 0 || bodyStyles.includes(current.bodyStyle)),
        ),
      [bodyStyles, vehicleType, vehicles],
    );

    const title = useMemo(() => {
      /** @todo: use translations */
      switch (vehicleType) {
        case 'diesel':
          return 'Choose a Diesel vehicle to compare';
        case 'electric':
          return 'Choose an EV to compare';
        case 'hybrid':
          return 'Choose a Hybrid to compare';
        case 'petrol':
          return 'Choose a Petrol vehicle to compare';
        default:
          return 'Choose a vehicle to compare';
      }
    }, [vehicleType]);

    const handleSelect = useCallback(
      (vehicle: Vehicle) => {
        dispatch({
          type: 'SET_PREFERRED_VEHICLE',
          vehicleType: vehicle.type,
          orderKey: vehicle.orderKey,
        });
        sidebar.current?.close();
      },
      [dispatch],
    );

    return (
      <SidebarTemplate
        id="sidebar-vehiclechange"
        onChange={handleChange}
        {...props}
        ref={sidebar}
        title={title}>
        <div className={styles.sidebarHeader}>
          <BodyStyleCombobox multiple onChange={setBodyStyles} />
          <HorizontalRule />
        </div>
        {displayedVehicles && displayedVehicles.length > 0 ? (
          <div className={styles.vehicleListContainer}>
            {displayedVehicles?.map((vehicle) => (
              <VehicleCard
                key={vehicle.orderKey}
                onSelect={handleSelect}
                vehicle={vehicle}
              />
            ))}
          </div>
        ) : (
          // @todo: update design + copy (from language files).
          <div className={styles.noResultsPlaceholder}>
            None of the available vehicles match your criteria.
          </div>
        )}
        <div className={styles.sidebarFooter}>
          <HorizontalRule />
          <Button
            children="Cancel"
            full
            onClick={() => sidebar.current?.close()}
            secondary
          />
        </div>
      </SidebarTemplate>
    );
  };

const VehicleCard: ComponentType<{
  onSelect: (vehicle: Vehicle) => void;
  vehicle: Vehicle;
}> = function VehicleCard({ onSelect, vehicle }) {
  const { t } = useTranslation();
  const formatNumber = useFormatNumber();
  const formatCurrency = useFormatCurrency();

  const [
    {
      fields: { annualMileage, contractLength },
    },
  ] = useQuestionnaireContext();

  const handleSelect = useCallback<
    NonNullable<ComponentPropsWithoutRef<typeof Button>['onClick']>
  >(() => onSelect(vehicle), [onSelect, vehicle]);

  return (
    <Card className={styles.vehicleCard}>
      <div
        className={styles.vehicleCardHeader}
        children={t(`shared.vehicles.types.${vehicle.type}`)}
      />
      <div className={styles.vehicleCardBody}>
        {/** @todo: change placeholder-image */}
        <img
          src={vehicle.imageURL || '/static/images/placeholder-vehicle.png'}
          alt={vehicle.name}
        />
        <div className={styles.vehicleCardTitle}>
          <Heading title={vehicle.name} level={4} />
          <Heading title={vehicle.performance} level={5} />
        </div>
        <div className={styles.vehicleCardContractDetailsList}>
          <div className={styles.item}>
            <p children={t('shared.inputs.annual_mileage.label')} />
            <p children={formatNumber(annualMileage * 1000)} />
          </div>
          <div className={styles.item}>
            <p children={t('shared.inputs.length_of_contract.label')} />
            <p>{formatNumber(contractLength)} months</p>
          </div>
        </div>
        <HorizontalRule />
        <div className={styles.vehicleCardPaymentDetailsList}>
          <div className={styles.item}>
            <p>{t('shared.vehicle_payment')}*</p>
            <p
              children={formatCurrency(vehicle.costs.monthly.vehiclePayment)}
            />
          </div>
          <div className={styles.item}>
            <p children={t('shared.total_monthly_costs')} />
            <p children={formatCurrency(vehicle.costs.totalMonthlyPayment)} />
          </div>
        </div>
        <Button onClick={handleSelect}>Select Vehicle</Button>
        <div className={styles.vehicleCardTerms}>
          {t('shared.vehicle_payment_compliance', {
            deposit: formatCurrency(vehicle.costs.deposit),
            final_payment: formatCurrency(vehicle.costs.finalPayment),
            apr: vehicle.costs.apr.value,
          })}
        </div>
      </div>
    </Card>
  );
  // return <h1>{vehicle.name}</h1>;
};

const ChangeVehicleSidebar: typeof ChangeVehicleSidebarBase =
  // eslint-disable-next-line react/display-name
  forwardRef<Element, Props>((props, ref) =>
    ChangeVehicleSidebarBase({ ...props, ref }),
  );

export default ChangeVehicleSidebar;
