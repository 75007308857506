import { ComponentPropsWithoutRef } from 'react';

import ButtonGroup from '@/components/atoms/ButtonGroup';
import styles from '@/components/molecules/RadioButtonGroup.module.scss';

type Props<T> = Pick<
  ComponentPropsWithoutRef<typeof ButtonGroup>,
  'onChange' | 'value'
> & {
  items: ComponentPropsWithoutRef<typeof ButtonGroup<T>>['items'];
  errorMessage?: string;
};

const RadioButtonGroup = function RadioButtonGroup<T>(props: Props<T>) {
  return (
    <div className={styles.buttonGroup}>
      <ButtonGroup
        {...props}
        auto
        buttonGroupType="radio"
        buttonGroupName="button-group"
      />
    </div>
  );
};

export default RadioButtonGroup;
