import { TagList as BronsonTagList } from '@vwfs-bronson/bronson-react';
import { ComponentType, ReactNode } from 'react';

type Props = {
  children?: ReactNode;
};

const TagList: ComponentType<Props> = function TagList(props) {
  return <BronsonTagList {...props} />;
};

export default TagList;
