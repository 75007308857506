import { useCallback, useMemo } from 'react';

import { useQuestionnaireContext } from '@/providers/QuestionnaireContextProvider';
import { api } from '@/services/fes/api';
import {
  VehicleType,
  Vehicle,
  VehicleTypes,
  Fuel,
  BodyStyleOrUnknown,
  MonthlyExpenseCategories,
} from '@/services/fes/types';
import { sum } from '@/utils/array';

type FetchQuestionnaireRequestBody = {
  contract: Record<'monthlyMaxPayment' | 'length', number>;
  annualMileage: number;
  costs: Record<Fuel, number>;
};

type FetchQuestionnaireResponseBody = {
  vehicles: Vehicle[];
};

const questionnaireApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchQuestionnaireResults: build.query<
      FetchQuestionnaireResponseBody['vehicles'],
      FetchQuestionnaireRequestBody
    >({
      query: (body) => ({
        url: 'questionnaire',
        method: 'post',
        body,
      }),
      transformResponse: (res: FetchQuestionnaireResponseBody) =>
        (res.vehicles || []).map((vehicle) => ({
          ...vehicle,
          costs: {
            ...vehicle.costs,
            totalMonthlyPayment:
              vehicle.costs.totalMonthlyPayment ??
              sum(
                MonthlyExpenseCategories.reduce(
                  (prev, category) => prev + vehicle.costs.monthly[category],
                  0,
                ),
              ),
          },
        })),
    }),
  }),
});

const useFetchQuestionnaireResultsRequestBody = () => {
  const [{ fields }] = useQuestionnaireContext();

  return {
    annualMileage: fields.annualMileage,
    contract: {
      length: fields.contractLength ?? 0,
      monthlyMaxPayment: fields.maximumVehiclePayment ?? 0,
    },
    costs: {
      diesel: fields.costOfDiesel,
      electricity: fields.costOfElectricity,
      petrol: fields.costOfPetrol,
    },
  };
};

export const useQuestionnaireVehicles = () => {
  const res = questionnaireApi.useFetchQuestionnaireResultsQuery(
    useFetchQuestionnaireResultsRequestBody(),
  );

  // IMPORTANT!
  // We expect vehicles to be ordered by price when
  // displaying the cheapest vehicles.
  return {
    ...res,
    data: [...(res.data || [])].sort(
      (a, b) => a.costs.totalMonthlyPayment - b.costs.totalMonthlyPayment,
    ),
    currentData: [...(res.currentData || [])].sort(
      (a, b) => a.costs.totalMonthlyPayment - b.costs.totalMonthlyPayment,
    ),
  };
};

export const useQuestionnaireChosenVehicles = (): Omit<
  ReturnType<typeof useQuestionnaireVehicles>,
  'currentData' | 'data'
> & {
  currentData: Record<VehicleType, Vehicle | undefined>;
  data: Record<VehicleType, Vehicle | undefined>;
} => {
  const [
    {
      fields: { bodyStyles = [], maximumVehiclePayment },
      preferredVehicle,
    },
  ] = useQuestionnaireContext();
  const filterAvailableByType = useCallback(
    (vehicles: Vehicle[] | undefined, forType: VehicleType) => {
      if (preferredVehicle[forType]) {
        const vehicle = vehicles?.find(
          (current) => current.orderKey === preferredVehicle[forType],
        );
        if (vehicle) return [vehicle];
      }
      return vehicles?.filter(
        ({ type, bodyStyle }) =>
          type === forType &&
          (bodyStyles.length === 0 ||
            bodyStyles.includes('unknown') ||
            bodyStyles.includes(
              String(bodyStyle).toLocaleLowerCase() as BodyStyleOrUnknown,
            )),
      );
    },
    [bodyStyles, preferredVehicle],
  );

  const query = useQuestionnaireVehicles();
  return {
    ...query,
    currentData: useMemo(
      () =>
        VehicleTypes.reduce(
          (carry, type) => ({
            ...carry,
            [type]: getBestMatch(
              filterAvailableByType(query.currentData, type) || [],
              maximumVehiclePayment || 0,
            ),
          }),
          {} as Record<VehicleType, Vehicle | undefined>,
        ),
      [filterAvailableByType, maximumVehiclePayment, query.currentData],
    ),
    data: useMemo(
      () =>
        VehicleTypes.reduce(
          (carry, type) => ({
            ...carry,
            [type]: getBestMatch(
              filterAvailableByType(query.data, type) || [],
              maximumVehiclePayment || 0,
            ),
          }),
          {} as Record<VehicleType, Vehicle | undefined>,
        ),
      [filterAvailableByType, maximumVehiclePayment, query.data],
    ),
  };
};

const getBestMatch = (
  vehicles: Vehicle[],
  maximumVehiclePayment: number,
): Vehicle | null => {
  const bestMatchByTotalCost = vehicles
    .filter(
      (vehicle) => vehicle.costs.totalMonthlyPayment < maximumVehiclePayment,
    )
    .reverse()[0];
  if (bestMatchByTotalCost) {
    return bestMatchByTotalCost;
  }
  return vehicles[0];
};

export const useQuestionnaireCheapestVehicle = (): Omit<
  ReturnType<typeof useQuestionnaireChosenVehicles>,
  'currentData' | 'data'
> & {
  currentData?: Vehicle;
  data?: Vehicle;
} => {
  const query = useQuestionnaireVehicles();

  return {
    ...query,
    currentData: useMemo(() => query.currentData?.[0], [query.currentData]),
    data: useMemo(() => query.data?.[0], [query.data]),
  };
};
