import {
  FormHeading as BronsonFormHeading,
  InfoIcon,
} from '@vwfs-bronson/bronson-react';
import { ComponentType, ReactNode, useMemo } from 'react';

import styles from '@/components/atoms/FormHeading.module.scss';

type Props = {
  large?: boolean;
  title: string | ReactNode;
  tooltip?: ReactNode;
  description?: string;
};

const FormHeading: ComponentType<Props> = function FormHeading({
  large,
  title,
  tooltip,
  ...props
}) {
  const infoIcon = useMemo(() => {
    if (!tooltip) return null;
    return (
      <InfoIcon
        icon="semantic-info"
        children={tooltip}
        style={{ marginLeft: '7px' }}
      />
    );
  }, [tooltip]);
  return (
    <BronsonFormHeading
      className={[styles.formHeading, large && styles.large].join(' ')}
      {...props}>
      {title}
      {infoIcon}
      {/* NOTE: when FormHeading is used inside Bronson's Media Teaser Section, the icon jumps up and down on hover, because of ".c-media-teaser__caption *:last-child" */}
      <div style={{ display: 'none' }} />
    </BronsonFormHeading>
  );
};

export default FormHeading;
