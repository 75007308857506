import FormHeading from '@/components/atoms/FormHeading';
import Heading from '@/components/atoms/Heading';
import styles from '@/screens/Questionnaire/BudgetScreen.module.scss';
import MaximumVehiclePaymentField from '@/components/organisms/MaximumVehiclePaymentField';
import ContractLengthField from '@/components/organisms/ContractLengthField';
import { useFetchConfigurationQuery } from '@/services/fes';

const BudgetScreen = function BudgetScreen() {
  const configuration = useFetchConfigurationQuery();
  const { data: { localisation } = {} } = configuration;

  return (
    <>
      <Heading
        level={2}
        title={localisation?.pages?.journey?.financeBudget?.header}
      />
      <FormHeading
        title={localisation?.pages?.journey?.financeBudget?.paymentHeader}
        description={localisation?.pages?.journey?.financeBudget?.paymentBody}
      />
      <MaximumVehiclePaymentField />
      <div className={styles.header}>
        <FormHeading
          title={localisation?.pages?.journey?.financeBudget?.contractHeader}
          description={
            localisation?.pages?.journey?.financeBudget?.contractBody
          }
        />
      </div>
      <ContractLengthField />
    </>
  );
};

export default BudgetScreen;
