import { ComponentType, Fragment } from 'react';

import StackedBar from '@/components/atoms/StackedBar';
import styles from '@/components/molecules/StackedBarMolecule.module.scss';

type Props = {
  title?: string;
  description?: string;
  showTotal?: boolean;
  variant?: 'small' | 'default' | 'labelOnly';
  max?: number;
  formatValue?: (v: number) => string;
  hint?: string;
  label?: string;
  values: {
    label?: string;
    color: string;
    value: number;
  }[];
  labelVariant?: 'black' | 'default';
};

const StackedBarMolecule: ComponentType<Props> = function StackedBarMolecule({
  formatValue = (v) => `${v}`,
  values,
  title,
  description,
  showTotal,
  max,
  variant,
  hint,
  label,
  labelVariant = 'default',
}) {
  const total = (values || []).reduce(
    (previous, { value }) => previous + value,
    0,
  );

  return (
    <div className={styles.container}>
      <div className={styles.stackBarContainer}>
        <Fragment key={label}>
          {label && (
            <span className={styles[`label-${labelVariant}`]}>{label}</span>
          )}
          {variant !== 'labelOnly' && (
            <StackedBar
              formatValue={formatValue}
              hint={hint}
              key={label}
              max={max}
              values={values}
              variant={variant}
            />
          )}
        </Fragment>
        {showTotal && (
          <span className={styles.stackBarTotal}>{formatValue(total)}</span>
        )}
      </div>
      <div className={styles.descriptionContainer}>
        <div className={styles.descriptionColumn}>
          {title && <h5 className={styles.title}>{title}</h5>}
          {description && (
            <span className={styles.description}>{description}</span>
          )}
        </div>
        <div className={styles.stackBarTotalPlaceholder} />
      </div>
    </div>
  );
};

export default StackedBarMolecule;
