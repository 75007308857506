import {
  ComponentType,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import Button from '@/components/atoms/Button';
import styles from '@/components/templates/SidebarTemplate.module.scss';
import Heading from '@/components/atoms/Heading';

type Props = PropsWithChildren<{
  id?: string;
  confirmOnClose?: string;
  description?: string;
  onChange?: (open: boolean) => void;
  onClose?: () => void;
  onOpen?: () => void;
  openByDefault?: boolean;
  ref?: ForwardedRef<Element>;
  title?: string;
}>;

type Element = {
  open: () => void;
  close: (force?: boolean) => void;
};

const SidebarTemplateBase: ComponentType<Props> = function SidebarTemplateBase({
  id,
  children,
  confirmOnClose,
  description,
  onChange,
  onClose,
  onOpen,
  openByDefault = false,
  ref,
  title,
}) {
  const [open, internalSetOpen] = useState(openByDefault);
  const setOpen = useCallback(
    (value: boolean) => {
      if (value === open) return;
      internalSetOpen(value);
      if (value === true) onOpen?.();
      if (value === false) onClose?.();
      onChange?.(value);
    },
    [open, onOpen, onClose, onChange],
  );

  const handleClose = useCallback(
    (force = false) => {
      const shouldClose =
        force === true ||
        Boolean(confirmOnClose) === false ||
        // eslint-disable-next-line no-alert
        window.confirm(confirmOnClose);
      if (shouldClose === false) return;
      setOpen(false);
    },
    [confirmOnClose, setOpen],
  );

  // Expose controls via ref.
  useImperativeHandle(
    ref,
    () => ({
      open: () => setOpen(true),
      close: handleClose,
    }),
    [handleClose, setOpen],
  );

  // Disable scroll on body while the Sidebar is open.
  useLayoutEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <Portal id={id}>
      <div className={styles.container}>
        {/* eslint-disable-next-line
              jsx-a11y/click-events-have-key-events,
              jsx-a11y/no-static-element-interactions
        */}
        <div className={styles.backdrop} onClick={() => handleClose()} />
        <div className={styles.sidebar}>
          <div className={styles.heading}>
            <Button
              icon="semantic-close"
              simple
              small
              link
              hiddenLabel
              onClick={() => handleClose()}>
              Close
            </Button>
            {title && <Heading level={2} title={title} />}
            {description && <p>{description}</p>}
          </div>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </Portal>
  );
};

// eslint-disable-next-line react/display-name
const SidebarTemplate: typeof SidebarTemplateBase = forwardRef<Element, Props>(
  (props, ref) => SidebarTemplateBase({ ...props, ref }),
);

type PortalProps = PropsWithChildren<{
  id?: string;
}>;

const Portal: ComponentType<PortalProps> = function Portal({ id, children }) {
  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement>();

  useLayoutEffect(() => {
    const element = document.createElement('div');
    if (id) {
      element.setAttribute('id', id);
    }
    document.body.appendChild(element);
    setWrapperElement(element);
    return () => {
      element.parentNode?.removeChild(element);
    };
  }, [id]);

  if (!wrapperElement) {
    return null;
  }

  return createPortal(children, wrapperElement);
};

export default SidebarTemplate;
