import { TrackingState } from '@/providers/TrackingProvider';
import { Vehicle } from '@/services/fes';

export const convertVehicleToTrackingProduct = (
  vehicle: Vehicle,
): NonNullable<TrackingState['product']>[number] => ({
  productAddons: [],
  productID: null,
  productVariants: [],
  subname: [],
  category: 'Financing',
  name: 'AutoCredit & ClassicCredit',
  attributes: {
    addOns: [
      {
        additionalInformation: null,
        name: null,
      },
    ],
    balloonPayment: null,
    contractAmount: null,
    contractMileage: null,
    deductible: null,
    downPaymentAmount: null,
    effectiveInterestRate: null,
    endDateOfContract: null,
    excessMileage: null,
    grossLoanAmount: null,
    netLoanAmount: null,
    nominalInterestAmount: null,
    nominalInterestRate: null,
    paymentType: null,
    preTaxSubstraction: null,
    registrationDateOwner: null,
    specialAmount: null,
    specialConditions: null,
    startDateOfContract: null,
    termination: {
      type: null,
      reason: null,
    },
    typeOfSale: null,
    recurringPayment: `${vehicle.costs.monthly.vehiclePayment}`,
    duration: `${vehicle.contractLength}`,
    durationUnit: 'MONTH',
    yearlyMileage: `${vehicle.annualMileage * 1000}`,
    mileageUnit: 'MILES',
    currency: 'GBP', // TODO
    paymentFrequency: 'monthly payment',
  },
  vehicleModel: [
    {
      basePrice_localCurrency: null,
      category: null,
      certifiedPreOwned: null,
      colorExterior: null,
      condition: null,
      currentMileage: null,
      currentMileageUnit: null,
      endPrice_localCurrency: null,
      equity: null,
      initialRegistrationDate: null,
      manufacturerCode: null,
      priorModel: null,
      salesID: null,
      typeCode: null,
      year: null,
      manufacturer: vehicle.manufacturer,
      name: vehicle.name,
      modelVariation: vehicle.performance,
      typeOfUse: 'private',
      bodyType: vehicle.bodyStyle,
      engine: {
        fuelType: vehicle.type,
        emission: `${vehicle.emission.value} ${vehicle.emission.unit}`,
      },
    },
  ],
});
