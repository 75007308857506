import { ComponentPropsWithoutRef, ComponentType } from 'react';

import MediaTeaser from '@/components/molecules/MediaTeaser';
import styles from '@/components/organisms/MediaTeaserSection.module.scss';

type MediaTeaserSectionProps = ComponentPropsWithoutRef<typeof MediaTeaser>;

const MediaTeaserSection: ComponentType<MediaTeaserSectionProps> =
  function MediaTeaserSection(props) {
    return (
      <div className={styles.container}>
        <MediaTeaser {...props} />
      </div>
    );
  };

export default MediaTeaserSection;
