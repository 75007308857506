import { ComponentType, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';

import styles from '@/components/organisms/VehicleCardComparison.module.scss';
import Heading from '@/components/atoms/Heading';
import VehicleCard from '@/components/molecules/VehicleCard';
import TagList from '@/components/atoms/TagList';
import Tag from '@/components/atoms/Tag';
import {
  BodyStyle,
  BodyStyleOrUnknown,
  Vehicle,
  VehicleTypes,
  useFetchConfigurationQuery,
} from '@/services/fes';
import { useTracking } from '@/providers/TrackingProvider';

type Props = {
  vehicles: Vehicle[];
  onChangeVehicleSidebarChange?: (open: boolean) => void;
  onEditBodyStyleClick?: () => void;
  onRemoveBodyStyleClick: () => void;
};

const vehicleInformation = (
  <>
    The predicted monthly costs are some of the key outgoings when running and
    financing your vehicle. These are based on illustrative vehicles for each
    fuel type. Please note that the Solutions PCP finance costs displayed do not
    constitute an official financial quotation from VWFS UK Ltd. If you would
    like to calculate a finance example please visit one of our finance
    calculators on a VWG brand website for a more accurate calculation and an
    option to choose from a wider range of vehicles. Alternatively visit{' '}
    <a
      href="https://customer.vwfs.co.uk/electric-cars.html"
      target="_blank"
      rel="noreferrer">
      www.customer.vwfs.co.uk/electric-cars.html
    </a>{' '}
    for more information.
  </>
);

const VehicleCardComparison: ComponentType<Props> =
  function VehicleCardComparison({
    vehicles,
    onChangeVehicleSidebarChange,
    onEditBodyStyleClick,
    onRemoveBodyStyleClick,
  }) {
    const { t } = useTranslation();
    const configuration = useFetchConfigurationQuery();
    const { data: { localisation } = {} } = configuration;
    const [field, , { setValue }] = useField<BodyStyleOrUnknown[] | undefined>(
      'bodyStyles',
    );
    const { value = [] } = field;

    const max = useMemo(
      () =>
        vehicles.reduce(
          (previous, current) =>
            Math.max(previous, current.costs.totalMonthlyPayment),
          0,
        ),
      [vehicles],
    );

    const handleRemove = useCallback(
      (key: BodyStyle) => {
        const newValue = value.filter((current: string) => current !== key);

        setValue(newValue.length ? newValue : ['unknown']);
        onRemoveBodyStyleClick();
      },
      [setValue, value, onRemoveBodyStyleClick],
    );

    const track = useTracking();
    const handleEditBodyStyleClick = useCallback(() => {
      track({
        type: 'interaction',
        trackingStateUpdate: {
          core: {
            attributes: {
              viewChange: 'Edit body type',
            },
          },
        },
        cleanVehicle: true,
        cleanFilter: true,
      });
      onEditBodyStyleClick?.();
    }, [onEditBodyStyleClick, track]);

    return (
      <>
        <Heading
          className={styles.heading}
          title={t('pages.results.heading2')}
          level={2}
        />
        <div className={styles.bodytypes}>
          <TagList
            children={[
              onEditBodyStyleClick && (
                <Tag
                  key="add_body"
                  className={styles.addLabel}
                  info
                  close
                  children={t('shared.add_body')}
                  closeLabel={`${t('shared.add_body')}`}
                  onClick={handleEditBodyStyleClick}
                />
              ),
              ...(field.value?.length
                ? field.value
                    .filter((body) => body !== 'unknown')
                    .map((body) => (
                      <Tag
                        key={body}
                        close
                        closeLabel={`${t('shared.remove')}`}
                        onClick={() => {
                          if (body !== 'unknown') {
                            // eslint-disable-next-line no-void
                            void handleRemove(body);
                          }
                        }}>
                        {t(`shared.vehicles.bodies.${body}`)}
                      </Tag>
                    ))
                : []),
            ]}
          />
        </div>
        {VehicleTypes.map((type) =>
          vehicles.find((vehicle) => vehicle.type === type),
        ).map(
          (vehicle) =>
            vehicle && (
              <div key={vehicle.orderKey} className={styles.cardContainer}>
                <VehicleCard
                  showBar
                  max={max}
                  onChangeVehicleSidebarChange={onChangeVehicleSidebarChange}
                  vehicle={vehicle}
                  showDetails
                />
              </div>
            ),
        )}
        <div className={styles.details}>
          <span children={vehicleInformation} />
        </div>
      </>
    );
  };

export default VehicleCardComparison;
