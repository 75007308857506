import { ComponentType, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import StackedBarMolecule from '@/components/molecules/StackedBarMolecule';
import FormHeading from '@/components/atoms/FormHeading';
import styles from '@/components/organisms/CostSummaryComparison.module.scss';
import { useFormatCurrency } from '@/utils/translation';
import {
  MonthlyExpenseCategories,
  useFetchConfigurationQuery,
  Vehicle,
  VehicleType,
  VehicleTypes,
} from '@/services/fes';

type Props = {
  vehicles: Record<VehicleType, Vehicle | undefined>;
};

const CostSummaryComparison: ComponentType<Props> =
  function CostSummaryComparison({ vehicles: vehiclesByType }) {
    const { t } = useTranslation();
    const formatCurrency = useFormatCurrency();

    const configuration = useFetchConfigurationQuery();
    const { data: { localisation } = {} } = configuration;

    const highestTotalMonthlyCost = useMemo(
      () =>
        Object.values(vehiclesByType).reduce(
          (carry, current) =>
            Math.max(carry, current?.costs.totalMonthlyPayment ?? 0),
          0,
        ),
      [vehiclesByType],
    );

    return (
      <>
        <FormHeading
          title={t('pages.results.heading2')}
          tooltip={localisation?.results.vehiclesTooltip}
        />
        <div className={styles.container}>
          {VehicleTypes.map((type) => {
            const vehicle = vehiclesByType[type];
            if (!vehicle) return null;
            return (
              <div key={type} className={styles.stackbarContainer}>
                <StackedBarMolecule
                  max={highestTotalMonthlyCost}
                  values={MonthlyExpenseCategories.map((category) => ({
                    label: `${t(`shared.categories.${category}.title`)}`,
                    value: vehicle.costs.monthly[category],
                    color: `var(--stacked-bar-item-${category}-color)`,
                    displayValue: category === 'vehiclePayment',
                  }))}
                  showTotal
                  label={`${t(`shared.vehicles.types.${type}`)}`}
                  formatValue={formatCurrency}
                />
              </div>
            );
          })}
          <div className={styles.legendContainer}>
            {MonthlyExpenseCategories.map((category) => (
              <div key={category} className={styles.legendItem}>
                <div
                  key={`${category}-color`}
                  style={{
                    backgroundColor: `var(--stacked-bar-item-${category}-color)`,
                  }}
                />
                <p children={t(`shared.categories.${category}.title`)} />
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

export default CostSummaryComparison;
