import { ButtonContainer as BronsonButtonContainer } from '@vwfs-bronson/bronson-react';
import { ComponentType, PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  center?: boolean;
  centerSmall?: boolean;
  centerWrap?: boolean;
  left?: boolean;
  nav?: boolean;
  noWrap?: boolean;
  reverse?: boolean;
  reverseSmall?: boolean;
  right?: boolean;
  rightSmall?: boolean;
  stretch?: boolean;
  vertical?: boolean;
  verticalSmall?: boolean;
  wrapReverse?: boolean;
}>;

const ButtonContainer: ComponentType<Props> = function ButtonContainer(props) {
  return <BronsonButtonContainer {...props} />;
};

export default ButtonContainer;
