/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-globals */
import { cloneDeep, isEqual, merge, set } from 'lodash';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import config from '@/config';

declare global {
  interface Window {
    _satellite?: {
      track: (name: string, data?: TrackingState) => void;
      pageBottom: () => void;
    };
    adobeDataLayer: TrackingState[];
  }
}

export type TrackingState = {
  core: {
    stagingEnvironment: string;
    dataLayerVersion: string;
    pageInfo: {
      pageName: string | null;
      intendedCustomerDeviceType: string;
      version: string;
      releaseDate: string;
      language: string;
      market: string;
      publisher: string;
    };
    category: {
      primaryCategory: string;
      secondaryCategory: string;
      siteType: string;
      inventoryType: string;
      maturityLevel: string;
      contractType: string;
      productVariants: [];
      processType: null;
    };
    attributes: {
      journeyType: string;
      viewChange?: string | null;
      brand?: string;
      transactionID: null;
      context: null;
      faqText: null;
    };
  };
  universally: {
    numberOfProducts: null;
    nps: {
      topic: null;
      rating: null;
    }[];
  };
  error?: {
    errorCode: string | null;
    errorMessage: string | null;
    errorCausingURL: string | null;
  };
  product?: {
    productID: null;
    category: string;
    name: string;
    subname: [];
    productVariants: [];
    productAddons: [];
    attributes?: {
      typeOfSale: null;
      startDateOfContract: null;
      endDateOfContract: null;
      contractMileage: null;
      excessMileage: null;
      paymentType: null;
      registrationDateOwner: null;
      preTaxSubstraction: null;
      balloonPayment: null;
      contractAmount: null;
      netLoanAmount: null;
      grossLoanAmount: null;
      downPaymentAmount: null;
      nominalInterestAmount: null;
      nominalInterestRate: null;
      effectiveInterestRate: null;
      deductible: null;
      specialAmount: null;
      addOns: {
        name: null;
        additionalInformation: null;
      }[];
      specialConditions: null;
      termination: {
        type: null;
        reason: null;
      };
      recurringPayment: string | null;
      duration: string | null;
      durationUnit: string | null;
      yearlyMileage: string | null;
      mileageUnit: string | null;
      currency: string | null;
      paymentFrequency: string | null;
    };
    vehicleModel?: {
      year: null;
      salesID: null;
      category: null;
      certifiedPreOwned: null;
      currentMileage: null;
      currentMileageUnit: null;
      initialRegistrationDate: null;
      basePrice_localCurrency: null;
      endPrice_localCurrency: null;
      manufacturerCode: null;
      typeCode: null;
      colorExterior: null;
      priorModel: null;
      equity: null;
      condition: null;
      name: string | null;
      manufacturer: string | null;
      modelVariation: string | null;
      modelLine?: string | null;
      descriptionLong?: string | null;
      typeOfUse: string | null;
      bodyType: string | null;
      engine: {
        fuelType: string | null;
        emission: string | null;
        transmission?: string | null;
        enginePower?: string | null;
      };
    }[];
  }[];
  dealerData: {
    companyId: null;
    companyName: null;
    regionId: null;
    KVPS: null;
    address: {
      street: null;
      zipCode: null;
      city: null;
      state: null;
    };
  };
  form: {
    type: null;
    name: null;
    fieldValues: null;
    lastTouchedField: null;
    errorFields: null;
  };
  design: { resolutionBreakpoint: string | null };
  customerData: {
    loginStatus: boolean;
    loggedInUserGroup: string;
    yearOfBirth: null;
    gender: null;
    address: {
      zipCode: null;
    };
  };
  businessData: {
    companySize: null;
  };
  targeting: {
    pageTargetVersion: null;
    completeVisitPath: [];
  };
  video: {
    videoID: null;
    videoName: null;
    videoLength: null;
    videoViewed: null;
  };
  dataPrivacyStatement: {
    allowPostalAds: null;
    allowPhoneAds: null;
    allowElectronicAds: null;
    allowMailAds: null;
    allowDataTransfer: null;
    allowElectronicInvoices: null;
  };
  search: {
    term: null;
    results: null;
  };
  menuTracking: {
    breadCrumbs: [];
  };
  filter?: {
    filterName: string | null;
    filterAction?: string | null;
    filterValue: string[];
  }[];
  'filter.count'?: number;
  eventInfo: {
    eventType: string | null;
    eventAction: string | null;
    eventTargetURL?: string | null;
    linkInformation?: string | null;
  }[];
};

export type TrackingUpdate = {
  type: 'view' | 'interaction' | 'exit';
  trackingStateUpdate: PartialDeep<TrackingState>;
  cleanFilter?: boolean;
  cleanVehicle?: boolean;
};

export const initialTrackingContext: TrackingState = {
  core: {
    stagingEnvironment: config.environment,
    dataLayerVersion: '2.12',
    pageInfo: {
      pageName: null,
      intendedCustomerDeviceType: 'all',
      version: config.version,
      releaseDate: config.releaseDate,
      language: 'en',
      market: 'UK',
      publisher: 'local-UK',
    },
    category: {
      primaryCategory: 'Financing',
      secondaryCategory: 'Pre sales',
      siteType: 'Pre sales',
      inventoryType: 'not available',
      maturityLevel: 'not available',
      contractType: 'not available',
      productVariants: [],
      processType: null,
    },
    attributes: {
      journeyType: 'customer-facing-journey',
      brand: 'VWFS',
      viewChange: null,
      transactionID: null,
      context: null,
      faqText: null,
    },
  },
  universally: {
    numberOfProducts: null,
    nps: [
      {
        topic: null,
        rating: null,
      },
    ],
  },
  error: {
    errorCode: null,
    errorMessage: null,
    errorCausingURL: null,
  },
  design: {
    resolutionBreakpoint: null,
  },
  customerData: {
    gender: null,
    yearOfBirth: null,
    address: {
      zipCode: null,
    },
    loginStatus: false,
    loggedInUserGroup: 'private',
  },
  eventInfo: [
    {
      eventType: null,
      eventAction: null,
      eventTargetURL: null,
      linkInformation: null,
    },
  ],
  filter: [],
  dealerData: {
    companyId: null,
    companyName: null,
    regionId: null,
    KVPS: null,
    address: {
      street: null,
      zipCode: null,
      city: null,
      state: null,
    },
  },
  form: {
    type: null,
    name: null,
    fieldValues: null,
    lastTouchedField: null,
    errorFields: null,
  },
  businessData: {
    companySize: null,
  },
  targeting: {
    pageTargetVersion: null,
    completeVisitPath: [],
  },
  video: {
    videoID: null,
    videoName: null,
    videoLength: null,
    videoViewed: null,
  },
  dataPrivacyStatement: {
    allowPostalAds: null,
    allowPhoneAds: null,
    allowElectronicAds: null,
    allowMailAds: null,
    allowDataTransfer: null,
    allowElectronicInvoices: null,
  },
  search: {
    term: null,
    results: null,
  },
  menuTracking: {
    breadCrumbs: [],
  },
  product: [
    {
      productAddons: [],
      productID: null,
      productVariants: [],
      subname: [],
      category: 'Financing',
      name: 'AutoCredit & ClassicCredit',
      attributes: {
        addOns: [
          {
            additionalInformation: null,
            name: null,
          },
        ],
        balloonPayment: null,
        contractAmount: null,
        contractMileage: null,
        deductible: null,
        downPaymentAmount: null,
        effectiveInterestRate: null,
        endDateOfContract: null,
        excessMileage: null,
        grossLoanAmount: null,
        netLoanAmount: null,
        nominalInterestAmount: null,
        nominalInterestRate: null,
        paymentType: null,
        preTaxSubstraction: null,
        registrationDateOwner: null,
        specialAmount: null,
        specialConditions: null,
        startDateOfContract: null,
        termination: {
          type: null,
          reason: null,
        },
        typeOfSale: null,
        recurringPayment: null,
        duration: null,
        durationUnit: null,
        yearlyMileage: null,
        mileageUnit: null,
        currency: null,
        paymentFrequency: null,
      },
      vehicleModel: [],
    },
  ],
};

const emptyProduct = [
  {
    category: 'Financing',
    name: 'AutoCredit & ClassicCredit',
    attributes: {
      recurringPayment: null,
      duration: null,
      durationUnit: null,
      yearlyMileage: null,
      mileageUnit: null,
      currency: null,
      paymentFrequency: null,
    },
    vehicleModel: [],
  },
];

const emptyFilter = [
  {
    filterName: null,
    filterAction: null,
    filterValue: [],
  },
];

const emptyError = {
  errorCode: null,
  errorMessage: null,
  errorCausingURL: null,
};

export const TrackingContext = createContext<
  readonly [TrackingState, (update: TrackingUpdate) => void] | null
>(null);

export const useTracking = () => useTrackingContext()[1];

export const useTrackingContext = () => {
  const ctx = useContext(TrackingContext);
  if (!ctx) {
    throw new Error(
      'useTrackingContext should be used below TrackingContextProvider only',
    );
  }
  return ctx;
};

export const TrackingProvider = function ReducerProvider({
  children,
}: PropsWithChildren) {
  const [trackingState, setTrackingState] = useState(initialTrackingContext);

  const updateTrackingState = useCallback(
    ({
      type,
      trackingStateUpdate,
      cleanFilter,
      cleanVehicle,
    }: TrackingUpdate) =>
      setTrackingState((previousState) => {
        const currentState = cloneDeep(previousState);
        if (cleanVehicle) set(currentState, 'product', emptyProduct);
        if (cleanFilter) set(currentState, 'filter', emptyFilter);
        set(currentState, 'error', emptyError);
        set(
          currentState,
          'design.resolutionBreakpoint',
          getResolutionBreakpoint(),
        );

        switch (type) {
          case 'interaction':
            set(currentState, 'eventInfo', [
              {
                eventType: 'interAction',
                eventAction: 'Success',
              },
            ]);
            set(currentState, 'event', 'interaction');
            break;
          case 'view':
            set(currentState, 'eventInfo', [
              {
                eventType: 'pageView',
                eventAction: 'Success',
              },
            ]);
            set(currentState, 'event', 'page');
            break;
          case 'exit':
            set(currentState, 'eventInfo', [
              {
                eventType: 'Exit',
                eventAction: 'Request',
              },
            ]);
            set(currentState, 'event', 'exit');
            break;
          default:
            throw new Error(`Invalid event type: ${type}`);
        }
        const newState = merge(currentState, trackingStateUpdate);
        // Avoid actually updating the state by returning the original instance.
        if (isEqual(previousState, newState)) return previousState;
        return newState;
      }),
    [setTrackingState],
  );

  const journeyStarted = useRef(false);
  useEffect(() => {
    const eventType = trackingState.eventInfo?.[0]?.eventType;
    if (!eventType) {
      return;
    }
    const dataLayer = merge({}, initialTrackingContext, trackingState);
    if (!journeyStarted.current) {
      dataLayer.eventInfo.push({
        eventType: 'Journey',
        eventAction: 'Start',
      });
      journeyStarted.current = true;
    }
    if (window._satellite) {
      window.adobeDataLayer = window.adobeDataLayer || [];
      window.adobeDataLayer.push(dataLayer);
    }
    if (config.environment === 'dev') {
      const format = (v: unknown, key?: string): string => {
        if (v && Array.isArray(v)) {
          return v
            .map((value, index) => format(value, `${key}[${index}]`))
            .filter(Boolean)
            .join('\n');
        }
        if (v && typeof v === 'object') {
          return Object.entries(v as object)
            .map(([index, value]) =>
              format(value, `${key ? `${key}.` : ''}${index}`),
            )
            .filter(Boolean)
            .join('\n');
        }
        return `${key}\t${v}`;
      };
      console.log(format(dataLayer));
    }
  }, [trackingState]);

  const contextValue = useMemo(
    () => [trackingState, updateTrackingState] as const,
    [trackingState, updateTrackingState],
  );

  return <TrackingContext.Provider children={children} value={contextValue} />;
};

function getResolutionBreakpoint() {
  const width = window.innerWidth || screen.width;
  if (width > 1920) {
    return 'xxl';
  }
  if (width > 1600) {
    return 'xl';
  }
  if (width > 1280) {
    return 'l';
  }
  if (width > 960) {
    return 'm';
  }
  if (width > 720) {
    return 's';
  }
  return 'xs';
}
