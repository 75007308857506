import { Paragraph as BronsonParagraph } from '@vwfs-bronson/bronson-react';
import { ComponentType, ReactNode } from 'react';

type Props = {
  className?: string;
  children?: ReactNode;
};

const Paragraph: ComponentType<Props> = function Paragraph(props) {
  return <BronsonParagraph {...props} />;
};

export default Paragraph;
