import { Button as BronsonButton } from '@vwfs-bronson/bronson-react';
import { ComponentType, ReactNode } from 'react';

type Props = {
  id?: string;
  primary?: boolean;
  secondary?: boolean;
  children?: ReactNode;
  disabled?: boolean;
  icon?: string;
  iconReversed?: boolean;
  link?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  simple?: boolean;
  small?: boolean;
  round?: boolean;
  full?: boolean;
  action?: boolean;
  hiddenLabel?: boolean;
};

const Button: ComponentType<Props> = function Button({ primary, ...props }) {
  return <BronsonButton {...props} primary={primary && 'true'} />;
};

export default Button;
