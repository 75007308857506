import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProvider } from 'formik';

import HorizontalRule from '@/components/atoms/HorizontalRule';
import QuestionnaireFooter from '@/components/organisms/QuestionnaireFooter';
import QuestionnaireStepper from '@/components/organisms/QuestionnaireStepper';
import styles from '@/components/templates/QuestionnaireTemplate.module.scss';
import { useQuestionnaireForm } from '@/providers/QuestionnaireFormProvider';
import { useQuestionnaireContext } from '@/providers/QuestionnaireContextProvider';
import { useTracking } from '@/providers/TrackingProvider';

const Steps = ['budget', 'mileage', 'costs', 'style'] as const;
const trackingMap = ['Finance & Duration', 'Mileage', 'Fuel cost', 'Body type'];

type StepKey = typeof Steps[number];

const QuestionnaireTemplate = function QuestionnaireTemplate() {
  const track = useTracking();
  const { t } = useTranslation();
  const [state, dispatch] = useQuestionnaireContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentIndex = useMemo(
    () =>
      Steps.findIndex((current) => `/questionnaire/${current}` === pathname),
    [pathname],
  );
  const isLastStep = currentIndex >= Steps.length - 1;

  const labelsByKey: Record<StepKey, string> = {
    budget: t(`shared.steps.budget`),
    mileage: t(`shared.steps.mileage`),
    costs: t(`shared.steps.cost`),
    style: t(`shared.steps.style`),
  };
  const Labels = Steps.map((key) => labelsByKey[key]);

  const enabledFields = useMemo(
    () =>
      ({
        budget: ['contractLength' as const, 'maximumVehiclePayment' as const],
        mileage: ['annualMileage' as const],
        costs: [
          'costOfDiesel' as const,
          'costOfElectricity' as const,
          'costOfPetrol' as const,
        ],
        style: ['bodyStyles' as const],
      }[Steps[currentIndex]]),
    [currentIndex],
  );

  const { form } = useQuestionnaireForm(enabledFields);

  const handleNextStepClick = useCallback(async () => {
    const errors = await form.validateForm();
    if (
      Object.keys(errors).filter((field) =>
        (enabledFields as string[]).includes(field),
      ).length !== 0
    ) {
      console.warn('Validation failed', { errors });
      return;
    }

    track({
      type: 'interaction',
      trackingStateUpdate: {
        eventInfo: [
          {
            linkInformation: `Process step ${currentIndex + 1} completed`,
          },
        ],
      },
      cleanFilter: true,
      cleanVehicle: true,
    });

    await form.submitForm();
    const nextStep = currentIndex + 2;
    if (state.enabledStep < nextStep) {
      dispatch({
        type: 'COMPLETE_STEP',
        value: nextStep,
      });
      dispatch({
        type: 'SET_FIELDS',
        fields: { isSubmitted: true },
      });
    }
    navigate(
      isLastStep ? '/cost_review' : `/questionnaire/${Steps[currentIndex + 1]}`,
    );
  }, [
    currentIndex,
    dispatch,
    enabledFields,
    form,
    isLastStep,
    navigate,
    state.enabledStep,
    track,
  ]);

  useEffect(() => {
    if (currentIndex === -1) {
      return;
    }
    track({
      type: 'view',
      trackingStateUpdate: {
        core: {
          attributes: {
            viewChange: `Question page ${currentIndex + 1}: ${
              trackingMap[currentIndex]
            }`,
          },
          pageInfo: {
            pageName: 'Questionnaire',
          },
        },
      },
    });
  }, [currentIndex, track]);

  return (
    <div className={styles.container}>
      <div
        className={styles.contentContainer}
        children={
          <QuestionnaireStepper
            current={currentIndex}
            steps={Labels}
            onStepClick={(idx) => navigate(`/questionnaire/${Steps[idx]}`)}
          />
        }
      />
      <HorizontalRule />
      <FormikProvider value={form}>
        <div className={styles.contentContainer} children={<Outlet />} />
        <QuestionnaireFooter
          prevStep={Steps[currentIndex - 1]}
          prevStepLabel={`${t(`shared.back`)}`}
          onNextStepClick={handleNextStepClick}
          nextStepLabel={
            isLastStep
              ? `${t(`shared.confirm_your_answers`)}`
              : `${t(`shared.continue`)} ${currentIndex + 2}`
          }
        />
      </FormikProvider>
    </div>
  );
};

export default QuestionnaireTemplate;
