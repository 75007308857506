import { Heading as BronsonHeading } from '@vwfs-bronson/bronson-react';
import { ComponentType, ReactNode } from 'react';

type Props = {
  level?: number;
  title: string | ReactNode;
  className?: string;
};

const Heading: ComponentType<Props> = function Heading({
  className,
  title,
  level = 1,
}) {
  return (
    <BronsonHeading className={className} level={level} children={title} />
  );
};

export default Heading;
