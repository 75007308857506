import { ChangeEvent, ReactNode } from 'react';
import { Select as BronsonSelect } from '@vwfs-bronson/bronson-react';

type Props<T> = {
  error?: ReactNode;
  id?: string;
  type?: 'select';
  items: {
    key: T;
    label: ReactNode;
  }[];
  onChange?: (v: ChangeEvent<HTMLInputElement>) => void;
  defaultValue: T;
};

const Select = function Select<T>({ items, ...props }: Props<T>) {
  return (
    <BronsonSelect {...props}>
      {items.map(({ key, label }, index) => (
        <BronsonSelect.Item
          key={key}
          index={index}
          children={label}
          value={String(key)}
        />
      ))}
    </BronsonSelect>
  );
};

export default Select;
