import { useEffect, useRef } from 'react';

type UsePreviousOptions = {
  setInitialValue?: boolean;
};

function usePrevious<T>(
  value: T,
  { setInitialValue = false }: UsePreviousOptions,
) {
  const ref = useRef<T | null>(setInitialValue ? value : null);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export default usePrevious;
