import { ComponentPropsWithoutRef, ComponentType, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormatNumber } from '@/utils/translation';
import NumberInput from '@/components/molecules/NumberInput';
import { useFetchConfigurationQuery } from '@/services/fes/configuration';

const CostOfElctricityField: ComponentType<
  Partial<ComponentPropsWithoutRef<typeof NumberInput>>
> = function CostOfElctricityField({ name = 'costOfElectricity', ...props }) {
  const { t } = useTranslation();
  const formatNumber = useFormatNumber();

  const configuration = useFetchConfigurationQuery();
  const average = configuration.currentData?.averages?.electricity;
  const tooltip =
    configuration.currentData?.localisation?.form.costOfElectricity;
  const averageStr = useMemo(
    () =>
      [
        formatNumber(average?.value || 0, {
          minimumFractionDigits: 1,
        }),
        average?.unit.short,
      ].join(' '),
    [average?.unit.short, average?.value, formatNumber],
  );

  /** @todo: get from configuration / FES ? */
  const stepAmount = 1;
  const minValue = 0;
  const maxValue = 500;

  return (
    <NumberInput
      name={name}
      minValue={minValue}
      maxValue={maxValue}
      stepAmount={stepAmount}
      hint={average?.hint}
      label={`${t('shared.inputs.cost_of_electricity.label')}`}
      placeholder={averageStr}
      tooltip={tooltip}
      {...props}
    />
  );
};

export default CostOfElctricityField;
