import { ComponentRef, ComponentType, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import styles from '@/components/molecules/VehicleCard.module.scss';
import Card from '@/components/atoms/Card';
import ProductTable from '@/components/atoms/ProductTable';
import HorizontalRule from '@/components/atoms/HorizontalRule';
import Button from '@/components/atoms/Button';
import StackedBarMolecule from '@/components/molecules/StackedBarMolecule';
import IconList from '@/components/molecules/IconList';
import { useFormatCurrency, useFormatNumber } from '@/utils/translation';
import { MonthlyExpenseCategories, Vehicle } from '@/services/fes/types';
import ChangeVehicleSidebar from '@/components/organisms/ChangeVehicleSidebar';
import { useTracking } from '@/providers/TrackingProvider';
import { convertVehicleToTrackingProduct } from '@/utils/convertVehicleToTrackingProduct';

type Props = {
  showBar?: boolean;
  showDetails?: boolean;
  max: number;
  onChangeVehicleSidebarChange?: (open: boolean) => void;
  vehicle: Vehicle;
};

const VehicleCard: ComponentType<Props> = function VehicleCard({
  showBar,
  showDetails,
  max,
  onChangeVehicleSidebarChange,
  vehicle,
}) {
  const track = useTracking();
  const { t } = useTranslation();
  const formatNumber = useFormatNumber();
  const formatCurrency = useFormatCurrency();

  const sidebarRef = useRef<ComponentRef<typeof ChangeVehicleSidebar>>(null);

  const trackLinkClick = useCallback(() => {
    track({
      type: 'exit',
      trackingStateUpdate: {
        eventInfo: [
          {
            linkInformation: 'Go to marketing website - vehicle information',
            eventTargetURL: vehicle.learnMore.replace(/^https?:\/\//, ''),
          },
        ],
        product: [convertVehicleToTrackingProduct(vehicle)],
      },
      cleanVehicle: true,
    });
  }, [track, vehicle]);

  const handleChangeVehicleButtonClick = useCallback(() => {
    track({
      type: 'view',
      trackingStateUpdate: {
        core: {
          attributes: {
            viewChange: 'Change vehicle',
          },
        },
        product: [convertVehicleToTrackingProduct(vehicle)],
      },
      cleanVehicle: true,
      cleanFilter: true,
    });
    sidebarRef.current?.open();
  }, [track, vehicle]);

  return (
    <>
      <ChangeVehicleSidebar
        ref={sidebarRef}
        onChange={onChangeVehicleSidebarChange}
        vehicleType={vehicle.type}
      />
      <Card
        className={styles.card}
        children={
          <div className={styles.cardBody}>
            <div className={styles.cardHeader}>
              <StackedBarMolecule
                max={max}
                variant={showBar ? 'small' : 'labelOnly'}
                label={`${t(`shared.vehicles.types.${vehicle.type}`)}`}
                values={MonthlyExpenseCategories.map((category) => ({
                  label: `${t(`shared.categories.${category}.title`)}`,
                  value: vehicle.costs.monthly[category],
                  color: `var(--stacked-bar-item-${category}-color)`,
                }))}
                formatValue={formatCurrency}
                labelVariant="default"
              />
            </div>
            <div className={styles.vehicleContainer}>
              <div className={styles.imageDiv}>
                {showDetails && (
                  <div className={styles.iconContainer}>
                    <IconList
                      items={[
                        {
                          icon: 'emission',
                          content: `${t('shared.emission')} ${
                            vehicle.emission.value
                          } ${vehicle.emission.unit}`,
                        },
                      ]}
                    />
                  </div>
                )}
                <img
                  src={
                    // TODO: change placeholder-image
                    vehicle.imageURL || '/static/images/placeholder-vehicle.png'
                  }
                  alt={vehicle.name}
                />
              </div>
              <div className={styles.productTable}>
                <ProductTable
                  title={vehicle.name}
                  headingLevel={3}
                  children={
                    <>
                      <div className={styles.titleBottom}>
                        <p>{vehicle.performance}</p>
                      </div>
                      <div className={styles.tableContainer}>
                        <div className={styles.upperInformation}>
                          <div className={styles.tableRow}>
                            <div className={styles.tableKey}>
                              <p>{t('shared.inputs.annual_mileage.label')} </p>
                            </div>
                            <div className={styles.tableValue}>
                              <p>
                                {formatNumber(vehicle.annualMileage * 1000)}
                              </p>
                            </div>
                          </div>
                          <div className={styles.tableRow}>
                            <div className={styles.tableKey}>
                              <p>
                                {t('shared.inputs.length_of_contract.label')}
                              </p>
                            </div>
                            <div className={styles.tableValue}>
                              <p>
                                {t('shared.x_months', {
                                  amount: formatNumber(vehicle.contractLength),
                                })}
                              </p>
                            </div>
                          </div>
                        </div>
                        <HorizontalRule />
                        <div className={styles.lowerInformation}>
                          <div className={styles.tableRow}>
                            <div className={styles.tableKey}>
                              <p>{t('shared.vehicle_payment')}*</p>
                            </div>
                            <div className={styles.tableValue}>
                              <p>
                                {formatCurrency(
                                  vehicle.costs.monthly.vehiclePayment,
                                )}
                              </p>
                            </div>
                          </div>
                          <div className={styles.tableRow}>
                            <div className={styles.tableKey}>
                              <p>{t('shared.total_monthly_costs')}</p>
                            </div>
                            <div className={styles.tableValue}>
                              <p>
                                {formatCurrency(
                                  vehicle.costs.totalMonthlyPayment,
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                        {!showDetails && (
                          <div className={styles.description}>
                            <p>
                              *
                              {t('shared.vehicle_payment_compliance', {
                                deposit: formatCurrency(vehicle.costs.deposit),
                                final_payment: formatCurrency(
                                  vehicle.costs.finalPayment,
                                ),
                                apr: vehicle.costs.apr.value,
                              })}
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  }
                />
              </div>
            </div>
            {showDetails && (
              <div className={styles.modContainer}>
                <div className={styles.buttonContainer}>
                  <div className={styles.left}>
                    <Button secondary onClick={handleChangeVehicleButtonClick}>
                      {t('shared.change_vehicle')}
                    </Button>
                  </div>
                  <div className={styles.right}>
                    <Button
                      primary
                      onClick={() => {
                        trackLinkClick();
                        window.open(
                          vehicle.learnMore,
                          '_blank',
                          'noopener,noreferrer',
                        );
                      }}>
                      {t('shared.learn_more')}
                    </Button>
                  </div>
                </div>
                <div className={styles.description}>
                  *
                  {t('shared.vehicle_payment_compliance', {
                    deposit: formatCurrency(vehicle.costs.deposit),
                    final_payment: formatCurrency(vehicle.costs.finalPayment),
                    apr: vehicle.costs.apr.value,
                  })}
                </div>
              </div>
            )}
          </div>
        }
      />
    </>
  );
};

export default VehicleCard;
