import {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FieldMetaProps, useField } from 'formik';
import {
  ErrorMessage as BronsonErrorMessage,
  TileSelect as BronsonTileSelect,
  TileSelectGroup as BronsonTileSelectGroup,
} from '@vwfs-bronson/bronson-react';

import { BodyStyleOrUnknown } from '@/services/fes';
import styles from '@/components/molecules/BodyStyleSelector.module.scss';
import Heading from '@/components/atoms/Heading';
import CoupeIcon from '@/components/icons/CoupeIcon';
import EstateIcon from '@/components/icons/EstateIcon';
import HatchbackIcon from '@/components/icons/HatchbackIcon';
import MpvIcon from '@/components/icons/MpvIcon';
import QuestionMarkIcon from '@/components/icons/QuestionMarkIcon';
import SaloonIcon from '@/components/icons/SaloonIcon';
import SuvIcon from '@/components/icons/SuvIcon';
import VanIcon from '@/components/icons/VanIcon';
import { IconComponentType } from '@/components/icons/types';
import { toggleArrayValue } from '@/utils/array';

const options: Record<
  BodyStyleOrUnknown,
  {
    IconComponent: IconComponentType;
    label: string;
  }
> = {
  coupe: {
    label: 'coupe',
    IconComponent: CoupeIcon,
  },
  hatchback: {
    label: 'hatchback',
    IconComponent: HatchbackIcon,
  },
  saloon: {
    label: 'saloon',
    IconComponent: SaloonIcon,
  },
  estate: {
    label: 'estate',
    IconComponent: EstateIcon,
  },
  van: {
    label: 'van',
    IconComponent: VanIcon,
  },
  mpv: {
    label: 'mpv',
    IconComponent: MpvIcon,
  },
  suv: {
    label: 'suv',
    IconComponent: SuvIcon,
  },
  unknown: {
    label: 'unknown',
    IconComponent: QuestionMarkIcon,
  },
};

type Props = {
  values: BodyStyleOrUnknown[];
  nonEmpty?: boolean;
  meta?: FieldMetaProps<BodyStyleOrUnknown[] | undefined>;
  small?: boolean;
  onBodyStyleChange: (newValues: BodyStyleOrUnknown[]) => void;
};

const BodyStyleSelector: ComponentType<Props> = function BodyStyleSelector({
  values,
  meta,
  small,
  nonEmpty = false,
  onBodyStyleChange,
}) {
  const { t } = useTranslation();
  const [innerValues, setInnerValues] = useState<BodyStyleOrUnknown[]>(values);

  useEffect(() => {
    setInnerValues(values);
  }, [values]);

  const previousRef = useRef(innerValues);
  useEffect(() => {
    const previous = previousRef.current;
    previousRef.current = innerValues;
    if (previous === innerValues) return;

    if (
      innerValues.includes('unknown') &&
      previous?.includes('unknown') === false
    ) {
      const newValue = ['unknown'] as BodyStyleOrUnknown[];
      setInnerValues(newValue);
      onBodyStyleChange(newValue);
      return;
    }

    if (previous?.includes('unknown') && innerValues.length > 1) {
      const newValue = innerValues?.filter(
        (current) => current !== 'unknown',
      ) as BodyStyleOrUnknown[];
      setInnerValues(newValue);
      onBodyStyleChange(newValue);
    }
  }, [innerValues, onBodyStyleChange, setInnerValues]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValues = toggleArrayValue(
        innerValues as string[],
        event.target.value,
      ) as BodyStyleOrUnknown[];

      if (nonEmpty && !newValues.length) return;

      setInnerValues(newValues);
      onBodyStyleChange(newValues);
    },
    [innerValues, nonEmpty, onBodyStyleChange],
  );

  const items = useMemo(
    () =>
      Object.entries(options).map(([value, { IconComponent, label }]) => ({
        children: (
          <div className={styles.item}>
            <IconComponent size="64px" />
            <Heading level={4} title={t(`shared.vehicles.bodies.${label}`)} />
          </div>
        ),
        value: value as BodyStyleOrUnknown,
      })),
    [t],
  );

  const [{ value: isSubmitted }] = useField('isSubmitted');

  const hasError = Boolean(isSubmitted && meta?.error?.length);

  return (
    <div className={styles.group} id="tileselect-body">
      <BronsonTileSelectGroup
        className={styles.container}
        layoutItemClassName={small ? 'u-1/2' : 'u-1/4 u-1/2@s'}>
        {items.map((itemProps) => (
          <BronsonTileSelect
            key={`bodyStyles/${itemProps.value}/${values.includes(
              itemProps.value,
            )}`}
            error={hasError}
            name="bodyStyles"
            onChange={handleChange}
            checked={innerValues.includes(itemProps.value)}
            {...itemProps}
          />
        ))}
      </BronsonTileSelectGroup>
      {hasError && (
        <BronsonErrorMessage>
          <br />
          {meta?.error}
        </BronsonErrorMessage>
      )}
    </div>
  );
};

export default BodyStyleSelector;
