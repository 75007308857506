import { ComponentPropsWithoutRef, ComponentType } from 'react';

import IconList from '@/components/molecules/IconList';
import styles from '@/components/molecules/IconGrid.module.scss';

type IconGridProps = ComponentPropsWithoutRef<typeof IconList>;

const IconGrid: ComponentType<IconGridProps> = function IconGrid(props) {
  return (
    <div className={styles.container}>
      <IconList {...props} />
    </div>
  );
};

export default IconGrid;
