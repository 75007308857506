import {
  ComponentType,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button';
import Heading from '@/components/atoms/Heading';
import HorizontalRule from '@/components/atoms/HorizontalRule';
import ResultsFooter from '@/components/organisms/ResultsFooter';
import styles from '@/screens/CostReviewScreen.module.scss';
import CostSummary from '@/components/organisms/CostSummary';
import { useFormatCurrency } from '@/utils/translation';
import {
  useFetchConfigurationQuery,
  useQuestionnaireChosenVehicles,
  Vehicle,
} from '@/services/fes';
import CostBreakdown from '@/components/organisms/CostBreakdown';
import VehicleCard from '@/components/molecules/VehicleCard';
import LoadableView from '@/components/templates/LoadableView';
import { useTracking } from '@/providers/TrackingProvider';
import { convertVehicleToTrackingProduct } from '@/utils/convertVehicleToTrackingProduct';
import Paragraph from '@/components/atoms/Paragraph';

const CostReviewScreen = function CostReviewScreen() {
  const track = useTracking();
  const chosenVehiclesQuery = useQuestionnaireChosenVehicles();
  const missingFuelTypes = useMemo(
    () =>
      Object.entries(chosenVehiclesQuery.data)
        .filter(([, value]) => !value)
        .map(([key]) => key),
    [chosenVehiclesQuery.data],
  );
  const electricVehicle = chosenVehiclesQuery.data.electric;

  useEffect(() => {
    if (chosenVehiclesQuery.isLoading) {
      return;
    }

    let viewChange = 'Cost review';
    if (!electricVehicle) {
      viewChange = 'No matching vehicle';
    } else if (missingFuelTypes.length > 0) {
      viewChange = `No matching vehicle for fuel type ${missingFuelTypes.join(
        ', ',
      )}`;
    }

    track({
      type: 'view',
      cleanVehicle: true,
      trackingStateUpdate: {
        core: {
          attributes: {
            viewChange,
          },
          pageInfo: {
            pageName: 'Cost review',
          },
        },
        eventInfo: [],
        ...(electricVehicle && {
          product: [convertVehicleToTrackingProduct(electricVehicle)],
        }),
      },
    });
  }, [chosenVehiclesQuery.isLoading, electricVehicle, missingFuelTypes, track]);

  const configuration = useFetchConfigurationQuery();
  const { data: { localisation } = {} } = configuration;

  return (
    <LoadableView
      className={styles.container}
      isLoading={chosenVehiclesQuery.isLoading}
      isFetching={chosenVehiclesQuery.isFetching}>
      {chosenVehiclesQuery.isLoading === false &&
        (electricVehicle ? (
          <>
            <CostReviewScreenContent
              displayedVehicle={electricVehicle}
              review={localisation?.pages.review}
            />
            <ResultsFooter hasResults />
          </>
        ) : (
          <>
            <NoResultsContent />
            <ResultsFooter hasResults={false} />
          </>
        ))}
    </LoadableView>
  );
};

const NoResultsContent = function NoResultsCOntent() {
  const navigate = useNavigate();
  const restartQuestionnaire = useCallback(
    () => navigate('/questionnaire'),
    [navigate],
  );

  return (
    <div className={styles.contentContainer}>
      {/* @todo: update design + copy (from language files). */}
      <Heading
        level={2}
        title="None of the available vehicles match your criteria."
      />
      {/* TODO: Update copy */}
      <p>Please adjust your responses to get your results.</p>
      <div className={styles.buttonContainer}>
        <Button onClick={restartQuestionnaire} primary>
          {/* TODO: Update copy */}
          Edit answers
        </Button>
      </div>
    </div>
  );
};

const CostReviewScreenContent: ComponentType<{
  displayedVehicle: Vehicle;
  review?: {
    description: {
      paragraph: string;
    }[];
    totalByFuelHeader: string;
  };
}> = function CostReviewScreenContent({ displayedVehicle, review }) {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const configuration = useFetchConfigurationQuery();
  const { data: { localisation } = {} } = configuration;

  return (
    <div className={styles.contentContainer}>
      {/* --- Intro --- */}
      <Intro description={review?.description} />
      <HorizontalRule />

      {/* --- Your Vehicle --- */}
      <Heading level={2} title={review?.totalByFuelHeader} />
      <VehicleCard
        showBar={false}
        max={displayedVehicle.costs.totalMonthlyPayment}
        vehicle={displayedVehicle}
      />

      {/* --- Cost Breakdown --- */}
      <Heading level={2} title={t('pages.cost_review.heading4')} />
      <CostBreakdown
        maximums={{
          fuel: displayedVehicle.costs.totalMonthlyPayment,
          roadTax: displayedVehicle.costs.totalMonthlyPayment,
          serviceAndMaintenance: displayedVehicle.costs.totalMonthlyPayment,
          vehiclePayment: displayedVehicle.costs.totalMonthlyPayment,
        }}
        vehicle={displayedVehicle}
      />

      {/* --- Cost Summary --- */}
      <Heading
        level={2}
        title={`${t('pages.cost_review.heading3', {
          amount: formatCurrency(displayedVehicle.costs.totalMonthlyPayment),
        })}`}
      />
      <CostSummary
        vehicle={displayedVehicle}
        max={displayedVehicle.costs.totalMonthlyPayment}
        description={`${t('shared.vehicle_payment_compliance', {
          deposit: formatCurrency(displayedVehicle.costs.deposit),
          final_payment: formatCurrency(displayedVehicle.costs.finalPayment),
          apr: displayedVehicle.costs.apr.value,
        })}`}
        title={`${t('shared.the_monthly_vehicle_payment_is_x', {
          amount: formatCurrency(displayedVehicle.costs.monthly.vehiclePayment),
        })}`}
      />
      <Paragraph
        className={styles.detail}
        children={localisation?.pages?.review?.disclaimer}
      />

      {/* --- */}
    </div>
  );
};

const Intro: ComponentType<{
  description?: {
    paragraph: string;
  }[];
}> = function Intro({ description }) {
  const { t } = useTranslation();

  return (
    <div className={styles.intro}>
      <Heading level={2} title={t('pages.cost_review.heading1')} />
      {description?.map((object, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={i}>
          <p>{object.paragraph}</p>
          {i < description.length - 1 && <br />}
        </Fragment>
      ))}
    </div>
  );
};

export default CostReviewScreen;
