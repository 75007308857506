import * as i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enGB from '@/languages/en-GB.json';
import deDE from '@/languages/de-DE.json';

const defaultNS = 'en-GB';
const resources = {
  'en-GB': {
    'en-GB': enGB,
  },
  'de-DE': {
    'de-DE': deDE,
  },
};

// eslint-disable-next-line no-void
void i18n.use(initReactI18next).init({
  lng: 'en-GB',
  defaultNS,
  resources,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
