import FormHeading from '@/components/atoms/FormHeading';
import Heading from '@/components/atoms/Heading';
import CostOfPetrolField from '@/components/organisms/CostOfPetrolField';
import CostOfDieselField from '@/components/organisms/CostOfDieselField';
import CostOfElctricityField from '@/components/organisms/CostOfElectricityField';
import { useFetchConfigurationQuery } from '@/services/fes';

const CostsScreen = function CostsScreen() {
  const configuration = useFetchConfigurationQuery();
  const { data: { localisation } = {} } = configuration;

  return (
    <>
      <Heading
        level={2}
        title={localisation?.pages?.journey?.fuelCosts?.header}
      />
      <FormHeading
        title={localisation?.pages?.journey?.fuelCosts?.inputHeader}
        description={localisation?.pages?.journey?.fuelCosts?.inputBody}
      />
      <CostOfPetrolField />
      <CostOfDieselField />
      <CostOfElctricityField />
    </>
  );
};

export default CostsScreen;
