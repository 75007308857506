import { ComponentType, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useField } from 'formik';

import Button from '@/components/atoms/Button';
import styles from '@/components/organisms/QuestionnaireFooter.module.scss';
import usePrevious from '@/hooks/usePrevious';

type Props = {
  className?: string;
  prevStep?: string;
  prevStepLabel?: string;
  nextStepLabel?: string;
  onNextStepClick?: () => void;
};

const QuestionnaireFooter: ComponentType<Props> = function QuestionnaireFooter({
  prevStep,
  prevStepLabel = 'Go Back',
  nextStepLabel = 'Next',
  onNextStepClick,
}) {
  const navigate = useNavigate();
  const goToStep = useCallback((key: string) => navigate(key), [navigate]);
  const [{ value: isSubmitted }, , { setValue }] = useField('isSubmitted');

  const previousPrevStep = usePrevious(prevStep, {});

  const handleNextStepClick = useCallback(() => {
    setValue(true);
    onNextStepClick?.();
  }, [onNextStepClick, setValue]);

  useEffect(() => {
    if (isSubmitted && previousPrevStep !== prevStep) {
      setValue(false);
    }
  }, [isSubmitted, prevStep, previousPrevStep, setValue]);

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        {prevStep && (
          <div className={styles.left}>
            <Button
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                if (prevStep) {
                  goToStep(prevStep);
                  e.currentTarget.blur();
                }
              }}
              secondary
              full>
              {prevStepLabel}
            </Button>
          </div>
        )}
        <div className={styles.right}>
          <Button
            disabled={!onNextStepClick}
            onClick={handleNextStepClick}
            primary
            full>
            {nextStepLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QuestionnaireFooter;
