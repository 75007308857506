import {
  ErrorMessage,
  FormField as BronsonFormField,
  InfoIcon,
} from '@vwfs-bronson/bronson-react';
import { ComponentType, ReactNode, useMemo } from 'react';

import styles from '@/components/atoms/FormField.module.scss';

type Props = {
  children?: ReactNode;
  error?: ReactNode;
  hint?: ReactNode;
  id?: string;
  labelText?: string;
  tooltip?: ReactNode;
  type: 'input' | 'select';
};

const FormField: ComponentType<Props> = function FormField({
  error,
  id,
  tooltip,
  ...props
}) {
  const errorMessage = useMemo(() => {
    if (!error) return null;
    return <ErrorMessage children={error} />;
  }, [error]);

  const infoIcon = useMemo(() => {
    if (!tooltip) return null;
    return <InfoIcon icon="semantic-info" children={tooltip} />;
  }, [tooltip]);

  return (
    <div className={styles.form}>
      <BronsonFormField
        id={id}
        errorMessage={errorMessage}
        infoIcon={infoIcon}
        labelForId={id}
        {...props}
      />
    </div>
  );
};

export default FormField;
