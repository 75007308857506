import { Wizard as BronsonWizard } from '@vwfs-bronson/bronson-react';
import { ReactNode } from 'react';

type WizardProps<T> = {
  collapsedCounter?: ReactNode;
  collapsedText?: ReactNode;
  collapsedTitle?: ReactNode;
  steps: {
    key: T;
    done?: boolean;
    error?: boolean;
    active?: boolean;
    disabled?: boolean;
    label: string;
  }[];
  onStepClick?: (key: T) => void;
};

const Wizard = function Wizard<T>({
  steps,
  onStepClick,
  ...props
}: WizardProps<T>) {
  return (
    <BronsonWizard {...props}>
      {steps.map(({ key, label, ...stepProps }) => (
        <BronsonWizard.Item
          key={key}
          children={label}
          onClick={() => onStepClick?.(key)}
          {...stepProps}
        />
      ))}
    </BronsonWizard>
  );
};

export default Wizard;
