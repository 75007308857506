import { IconComponentType } from '@/components/icons/types';

const QuestionMarkIcon: IconComponentType = function QuestionMarkIcon({
  color = 'currentColor',
  size = '36px',
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 36"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve">
      <g transform="matrix(1,0,0,1,15,10)">
        <g transform="matrix(1,0,0,1,1.22767,13.3984)">
          <g>
            <clipPath id="question_mark_icon_clip1">
              <path d="M1.363,0C2.115,0 2.725,0.582 2.725,1.301C2.725,2.02 2.115,2.602 1.363,2.602C0.611,2.602 0,2.02 0,1.301C0,0.582 0.611,0 1.363,0Z" />
            </clipPath>
            <g clipPath="url(#question_mark_icon_clip1)">
              <rect x="0" y="0" width="2.725" height="2.602" fill={color} />
            </g>
          </g>
        </g>
        <g>
          <clipPath id="question_mark_icon_clip2">
            <path d="M0,0C1.228,0 7,0.195 7,4.326C7,7.133 3.618,8.192 3.42,10.389C3.376,10.885 3.393,11.445 3.592,12.085L1.592,12.085C1.364,11.386 1.283,10.769 1.318,10.234C1.489,7.605 4.732,6.612 4.748,4.482C4.769,1.951 1.186,1.851 0.145,1.848L0,1.847L0,0Z" />
          </clipPath>
          <g clipPath="url(#question_mark_icon_clip2)">
            <rect x="0" y="0" width="7" height="12.085" fill={color} />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default QuestionMarkIcon;
