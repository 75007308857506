import { Spinner as BronsonSpinner } from '@vwfs-bronson/bronson-react';
import { ComponentType, ReactNode } from 'react';

type Props = {
  busy?: boolean;
  center?: boolean;
  children?: ReactNode;
  fullPage?: boolean;
  section?: boolean;
  small?: boolean;
};

const Spinner: ComponentType<Props> = function Spinner(props) {
  return <BronsonSpinner {...props} />;
};

export default Spinner;
