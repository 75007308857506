import { ComponentType, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '@/components/atoms/Button';
import styles from '@/components/organisms/QuestionnaireFooter.module.scss';

type Props = {
  hasResults?: boolean;
};

const ResultsFooter: ComponentType<Props> = function ResultsFooter({
  hasResults = true,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const goToStep = useCallback((key: string) => navigate(key), [navigate]);
  const restartQuestionnaire = useCallback(
    () => navigate('/questionnaire'),
    [navigate],
  );

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.left}>
          <Button onClick={() => goToStep('/questionnaire')} secondary full>
            {t('shared.back')}
          </Button>
        </div>
        <div className={styles.right}>
          {hasResults && (
            <Button onClick={() => goToStep('/results')} primary full>
              {t('shared.see_results')}
            </Button>
          )}
          {!hasResults && (
            <Button onClick={restartQuestionnaire} primary full>
              {/* TODO: Update copy */}
              Edit answers
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResultsFooter;
