import { FormSectionGroup as BronsonFormSectionGroup } from '@vwfs-bronson/bronson-react';
import { ComponentType, ReactNode } from 'react';

import styles from '@/components/atoms/FormSectionGroup.module.scss';

type Props = {
  id?: string;
  children?: ReactNode;
};

const FormSectionGroup: ComponentType<Props> = function FormSectionGroup({
  id,
  children,
}) {
  return (
    <BronsonFormSectionGroup
      id={id}
      className={styles.formSectionGroup}
      children={children}
    />
  );
};

export default FormSectionGroup;
