import { useEffect, useState } from 'react';

const useDebouncedValue = function useDebouncedValue<T>(
  nextValue: T,
  debounceMs: number,
) {
  const [value, setValue] = useState<T>(nextValue);
  useEffect(() => {
    const timeout = setTimeout(() => setValue(nextValue), debounceMs);
    return () => clearTimeout(timeout);
  }, [nextValue, debounceMs]);
  return value;
};

export default useDebouncedValue;
