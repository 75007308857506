import { ComponentPropsWithoutRef, ComponentType } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';

import { useFormatCurrency } from '@/utils/translation';
import NumberInput from '@/components/molecules/NumberInput';
import { useFetchConfigurationQuery } from '@/services/fes';

const MaximumVehiclePaymentField: ComponentType<
  Partial<ComponentPropsWithoutRef<typeof NumberInput>>
> = function MaximumVehiclePaymentField({
  name = 'maximumVehiclePayment',
  ...props
}) {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const [{ value }] = useField('isSubmitted');

  const configuration = useFetchConfigurationQuery();
  const { data: { localisation } = {} } = configuration;

  // TODO: get from backend (AEM)
  const stepAmount = 50;
  const minValue = localisation?.pages.journey.financeBudget.minValue || 300;
  const maxValue = localisation?.pages.journey.financeBudget.maxValue || 1000;

  return (
    <NumberInput
      id="input-payment"
      name={name}
      minValue={minValue}
      maxValue={maxValue}
      stepAmount={stepAmount}
      forceShowError={value}
      addonText="£"
      reversed
      hint={t('shared.inputs.maximum_vehicle_payment.hint', {
        minimum: formatCurrency(minValue, { minimumFractionDigits: 0 }),
        maximum: formatCurrency(maxValue, { minimumFractionDigits: 0 }),
      })}
      label={`${t('shared.inputs.maximum_vehicle_payment.label')}`}
      placeholder={`${minValue}`}
      tooltip={localisation?.pages.journey.financeBudget.paymentTooltip}
      {...props}
    />
  );
};

export default MaximumVehiclePaymentField;
