import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useFormatDate = function useFormatDate() {
  const {
    i18n: { languages },
  } = useTranslation();

  return useCallback(
    (d: Date) =>
      d.toLocaleDateString([...languages], {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
    [languages],
  );
};

export const useFormatNumber = function useFormatNumber() {
  const {
    i18n: { languages },
  } = useTranslation();

  return useCallback(
    (n?: number, options: Intl.NumberFormatOptions = {}) =>
      new Intl.NumberFormat([...languages], options).format(n ?? 0),
    [languages],
  );
};

export const useFormatCurrency = function useFormatCurrency() {
  const formatNumber = useFormatNumber();

  return (n: number, options: Intl.NumberFormatOptions = {}) =>
    formatNumber(n, {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      ...options,
    });
};
