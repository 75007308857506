import { Outlet } from 'react-router-dom';

import Heading from '@/components/atoms/Heading';
import styles from '@/components/templates/JourneyTemplate.module.scss';
import { useFetchConfigurationQuery } from '@/services/fes';

const TermsContainer = function TermsContainer() {
  return (
    <div className={styles.termsContainer}>
      <strong className={styles.termsTitle}>Important Information</strong>
      <div className={styles.sectionText}>
        <strong>Overview of tool &nbsp;&mdash;&nbsp;</strong>
        The VWFS Electric Car Calculator is designed to offer guidance on
        whether an EV may suit your budget, based on your answers to the
        questions provided. It should not be relied upon solely to inform your
        buying decision. We advise speaking to your local dealer and carefully
        considering your personal circumstances before making a final decision.
      </div>
      <div className={styles.sectionText}>
        <strong>Illustrative vehicles &nbsp;&mdash;&nbsp;</strong>
        The vehicles illustrated, and prices shown, were correct at the time
        this Electric Car Calculator tool was produced but are subject to change
        at any time and without notice. The indicative running cost result is a
        monthly figure, based on your required inputs, including the vehicle
        finance costs, fuel/energy costs, EV range, first year Vehicle Excise
        Duty, and servicing costs for the chosen model for each of the fuel
        types based on your answers. Road Tax is based on an average monthly
        cost, based on years two to four. The first years tax is included within
        the total vehicle price.
      </div>
      <div className={styles.sectionText}>
        <strong>Fuel information &nbsp;&mdash;&nbsp;</strong>
        The average petrol and diesel costs are sourced from and available on{' '}
        <a
          href="https://www.rac.co.uk/drive/advice/fuel-watch"
          target="_blank"
          rel="noreferrer">
          www.rac.co.uk/drive/advice/fuel-watch
        </a>
        . Furthermore, the average cost of electricity is taken from and
        available at{' '}
        <a
          href="https://www.gov.uk/government/publications/energy-bills-support/energy-bills-support-factsheet-8-september-2022"
          target="_blank"
          rel="noreferrer">
          https://www.gov.uk/government/publications/energy-bills-support/energy-bills-support-factsheet-8-september-2022
        </a>
        .
      </div>
      <div className={styles.sectionText}>
        <strong>Finance Information &nbsp;&mdash;&nbsp;</strong>
        With a Solutions Personal Contract Plan (PCP) you simply select your
        model, mileage and deposit. Monthly payments can then be spread across
        18 to 49 months. At the end of your agreement, you can choose to
        exchange it for a brand new model, pay the optional final payment and
        own the car, or hand it back (subject to terms and conditions). The
        deposit shown is based on 10% of the vehicle on the road price. Other
        finance products are available.
      </div>
    </div>
  );
};

const JourneyTemplate = function JourneyTemplate() {
  const configuration = useFetchConfigurationQuery();
  const { data: { localisation } = {} } = configuration;
  return (
    <>
      <div className={styles.headingContainer}>
        <Heading title={localisation?.pages?.landing?.title} level={1} />
      </div>
      <Outlet />
      <TermsContainer />
    </>
  );
};

export default JourneyTemplate;
