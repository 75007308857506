import { useField } from 'formik';
import { useCallback } from 'react';

import BodyStyleSelector from '@/components/molecules/BodyStyleSelector';
import Heading from '@/components/atoms/Heading';
import { BodyStyleOrUnknown, useFetchConfigurationQuery } from '@/services/fes';

const StyleScreen = function StyleScreen() {
  const configuration = useFetchConfigurationQuery();
  const { data: { localisation } = {} } = configuration;

  const [{ value = [] }, meta, { setValue }] = useField<
    BodyStyleOrUnknown[] | undefined
  >('bodyStyles');

  const handleChange = useCallback(
    (bodyStyles: BodyStyleOrUnknown[]) => {
      setValue(bodyStyles);
    },
    [setValue],
  );

  return (
    <>
      <Heading
        level={2}
        title={localisation?.pages?.journey?.bodyStyle?.header}
      />
      <p>{localisation?.pages?.journey?.bodyStyle?.inputBody}</p>
      <BodyStyleSelector
        values={value}
        meta={meta}
        onBodyStyleChange={handleChange}
      />
    </>
  );
};

export default StyleScreen;
