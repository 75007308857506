import { ComponentType, useMemo } from 'react';

import styles from '@/components/atoms/StackedBar.module.scss';

type Props = {
  values?: {
    label?: string;
    color: string;
    value: number;
    displayValue?: boolean;
  }[];
  max?: number;
  variant?: 'small' | 'default' | 'labelOnly';
  hint?: string;
  formatValue: (v: number) => string;
};

const StackedBar: ComponentType<Props> = function StackedBar({
  values = [],
  max: maxInput,
  variant = 'default',
  formatValue,
}) {
  const max = useMemo(() => {
    if (maxInput) return maxInput;
    return values.reduce((previous, { value }) => previous + value, 0) || 0;
  }, [maxInput, values]);

  const height = variant === 'default' ? '28px' : '12px';

  return (
    <div className={styles.container} style={{ height }}>
      {values.map((item) => (
        <div
          key={JSON.stringify(item)}
          className={styles.item}
          style={{
            width: `${(item.value / max) * 100}%`,
            backgroundColor: item.color,
          }}
          title={
            item.label
              ? `${item.label}: ${formatValue(item.value)}`
              : `${formatValue(item.value)}`
          }>
          {item.displayValue && (
            <div className={styles.itemContent}>{formatValue(item.value)}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default StackedBar;
