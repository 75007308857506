import { Tag as BronsonTag } from '@vwfs-bronson/bronson-react';
import { ComponentType, ReactNode } from 'react';

type Props = {
  className?: string;
  children?: ReactNode;
  close?: boolean;
  closeLabel?: string;
  positive?: boolean;
  negative?: boolean;
  info?: boolean;
  succes?: boolean;
  warning?: boolean;
  onClick?: () => void;
};

const Tag: ComponentType<Props> = function Tag(props) {
  return <BronsonTag {...props} />;
};

export default Tag;
