import {
  ComponentPropsWithoutRef,
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import Combobox from '@/components/molecules/Combobox';
import { BodyStyle, BodyStyles } from '@/services/fes';
import TagList from '@/components/atoms/TagList';
import Tag from '@/components/atoms/Tag';
import { useQuestionnaireContext } from '@/providers/QuestionnaireContextProvider';
import FormField from '@/components/atoms/FormField';

const BodyStyleCombobox: ComponentType<{
  onChange?: (selected: BodyStyle[]) => void;
  multiple?: boolean;
}> = function BodyStyleCombobox({ onChange, multiple }) {
  const { t } = useTranslation();
  const items = useMemo(
    () =>
      BodyStyles.map((key) => ({
        key,
        value: t(`shared.vehicles.bodies.${key}`),
      })),
    [t],
  );

  const [
    {
      fields: { bodyStyles = [] },
    },
  ] = useQuestionnaireContext();
  const [value, setValue] = useState(
    bodyStyles.filter((key) => key !== 'unknown') as BodyStyle[],
  );
  const [counter, setCounter] = useState(0);

  const handleChange = useCallback<
    NonNullable<ComponentPropsWithoutRef<typeof Combobox>['onChange']>
  >((selected) => setValue(selected.map((item) => item.key as BodyStyle)), []);

  const handleRemove = useCallback((key: BodyStyle) => {
    setValue((previous) => previous.filter((current) => current !== key));
    setCounter((previous) => previous + 1);
  }, []);

  useEffect(() => onChange?.(value), [onChange, value]);

  return (
    <FormField labelText="Body Style" type="select">
      <div style={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
        <Combobox
          key={counter}
          placeholder="Select Vehicle Body Style"
          items={items}
          defaultValue={value}
          onChange={handleChange}
          multiple={multiple}
        />
        {value.length !== 0 && (
          <TagList>
            {items
              .filter(({ key }) => value.includes(key))
              .map((item) => (
                <Tag
                  key={item.key}
                  children={item.value}
                  close
                  onClick={() => handleRemove(item.key)}
                />
              ))}
          </TagList>
        )}
      </div>
    </FormField>
  );
};

export default BodyStyleCombobox;

/* <Combobox
id="a"
name="a"
placeholder="Select Body Style"
items={items}
defaultValue={['coupe']}
onChange={console.log}
multiple
/> */
