import { useTranslation } from 'react-i18next';
import {
  ComponentRef,
  ComponentType,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';

import Heading from '@/components/atoms/Heading';
import CostSummaryComparison from '@/components/organisms/CostSummaryComparison';
import FormSectionGroup from '@/components/atoms/FormSectionGroup';
import FormSection from '@/components/atoms/FormSection';
import styles from '@/screens/ResultsScreen.module.scss';
import VehicleCardComparison from '@/components/organisms/VehicleCardComparison';
import HorizontalRule from '@/components/atoms/HorizontalRule';
import VehicleCostComparison from '@/components/organisms/VehicleCostComparison';
import FormHeading from '@/components/atoms/FormHeading';
import Button from '@/components/atoms/Button';
import BodyStyleSelectorSidebar from '@/components/organisms/BodyStyleSelectorSidebar';
import EditYourCostsSidebar from '@/components/organisms/EditYourCostsSidebar';
import MaximumVehiclePaymentField from '@/components/organisms/MaximumVehiclePaymentField';
import { useQuestionnaireForm } from '@/providers/QuestionnaireFormProvider';
import {
  useFetchConfigurationQuery,
  useQuestionnaireChosenVehicles,
  Vehicle,
  VehicleType,
} from '@/services/fes';
import LoadableView, {
  LoadableViewElement,
} from '@/components/templates/LoadableView';
import { useTracking } from '@/providers/TrackingProvider';
import { convertVehicleToTrackingProduct } from '@/utils/convertVehicleToTrackingProduct';
import { useQuestionnaireContext } from '@/providers/QuestionnaireContextProvider';

const ResultsScreen = function ResultsScreen() {
  const chosenVehicles = useQuestionnaireChosenVehicles();
  const containerRef = useRef<LoadableViewElement>(null);
  const handleApply = useCallback(
    () => containerRef.current?.simulateRefetch(),
    [],
  );
  const { currentData: configuration } = useFetchConfigurationQuery();

  return (
    <div className={styles.container}>
      <LoadableView
        ref={containerRef}
        className={[styles.contentContainer].join(' ')}
        isLoading={chosenVehicles.isLoading}
        isFetching={chosenVehicles.isFetching}>
        {!chosenVehicles.isLoading && (
          <ResultsScreenContent
            chosenVehicles={chosenVehicles.data}
            description={configuration?.localisation.results.description}
            isLoading={chosenVehicles.isLoading || chosenVehicles.isFetching}
            onApply={handleApply}
          />
        )}
      </LoadableView>
    </div>
  );
};

const ResultsScreenContent: ComponentType<{
  chosenVehicles: Record<VehicleType, Vehicle | undefined>;
  description?: {
    paragraph: string;
  }[];
  isLoading: boolean;
  onApply?: () => void;
}> = function ResultsScreenContent({
  chosenVehicles,
  description,
  isLoading,
  onApply,
}) {
  const navigate = useNavigate();
  useEffect(() => {
    if (!chosenVehicles.electric) {
      navigate('/cost_review');
    }
  }, [chosenVehicles.electric, navigate]);

  const track = useTracking();
  const { t } = useTranslation();
  const modalRefBodyStyleSelector =
    useRef<ComponentRef<typeof BodyStyleSelectorSidebar>>(null);
  const modalRefEditYourCosts =
    useRef<ComponentRef<typeof EditYourCostsSidebar>>(null);
  const [isBodyStyleSelectorOpen, setBodyStyleSelectorOpen] = useState(false);
  const [isEditCostsSidebarOpen, setEditCostsSidebarOpen] = useState(false);
  const [isChangeVehicleSidebarOpen, setChangeVehicleSidebarOpen] =
    useState(false);
  const isSidebarOpen =
    isBodyStyleSelectorOpen ||
    isEditCostsSidebarOpen ||
    isChangeVehicleSidebarOpen;

  const [{ fields }] = useQuestionnaireContext();
  const { form } = useQuestionnaireForm([
    'bodyStyles',
    'annualMileage',
    'maximumVehiclePayment',
    'contractLength',
    'costOfDiesel',
    'costOfPetrol',
    'costOfElectricity',
  ]);

  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  const isFirstDisplay = useRef(true);
  useEffect(() => {
    if (!mounted || isSidebarOpen || isLoading) {
      return;
    }

    const missingFuelTypes = Object.entries(chosenVehicles)
      .filter(([, value]) => !value)
      .map(([key]) => key);
    let viewChange = 'Result list';
    if (missingFuelTypes.length > 0) {
      viewChange = `No matching vehicle for fuel type ${missingFuelTypes.join(
        ', ',
      )}`;
    }
    track({
      type: 'view',
      trackingStateUpdate: {
        core: {
          attributes: {
            viewChange,
          },
          pageInfo: {
            pageName: 'Result list',
          },
        },
        ...(isFirstDisplay.current && {
          filter: [
            {
              filterName: 'Monthly payment',
              filterAction: 'Result list',
              filterValue: [`${fields.maximumVehiclePayment}`],
            },
            {
              filterName: 'Duration',
              filterAction: 'Result list',
              filterValue: [`${fields.contractLength}`],
            },
            {
              filterName: 'Yearly mileage',
              filterAction: 'Result list',
              filterValue: [`${fields.annualMileage}`],
            },
            {
              filterName: 'Body style',
              filterAction: 'Result list',
              filterValue: (fields.bodyStyles || []).map((current) => {
                if (current === 'unknown') {
                  return 'any';
                }
                return current;
              }),
            },
          ],
          'filter.count': 4,
        }),
        product: (
          Object.values(chosenVehicles).filter(Boolean) as Vehicle[]
        ).map(convertVehicleToTrackingProduct),
      },
      cleanVehicle: true,
      cleanFilter: true,
    });
    isFirstDisplay.current = false;
  }, [
    chosenVehicles,
    fields.annualMileage,
    fields.bodyStyles,
    fields.contractLength,
    fields.maximumVehiclePayment,
    isLoading,
    isSidebarOpen,
    mounted,
    track,
  ]);

  const submitForm = useCallback(async () => {
    const errors = await form.validateForm();
    if (Object.keys(errors).length !== 0) {
      console.warn('Validation failed', { errors });
      return;
    }
    await form.submitForm();
    onApply?.();
  }, [form, onApply]);

  return (
    <FormikProvider value={form}>
      <BodyStyleSelectorSidebar
        ref={modalRefBodyStyleSelector}
        onSubmitForm={submitForm}
        onChange={setBodyStyleSelectorOpen}
      />
      <EditYourCostsSidebar
        ref={modalRefEditYourCosts}
        onApply={submitForm}
        onChange={setEditCostsSidebarOpen}
      />

      <Heading level={2} title={t('pages.results.heading1')} />
      {description?.map((object, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <p key={i} className={styles.paragraph} children={object.paragraph} />
      ))}

      {/* Cost Comparison */}
      <CostSummaryComparison vehicles={chosenVehicles} />
      <EditMaximumVehiclePaymentSection onApply={submitForm} />

      {/* Vehicle Comparison */}
      <VehicleCardComparison
        onChangeVehicleSidebarChange={setChangeVehicleSidebarOpen}
        onEditBodyStyleClick={() => modalRefBodyStyleSelector.current?.open()}
        onRemoveBodyStyleClick={submitForm}
        vehicles={
          Object.values(chosenVehicles).filter(Boolean) as unknown as Vehicle[]
        }
      />
      <HorizontalRule />
      <VehicleCostComparison
        vehicles={chosenVehicles}
        onEditCostsClick={() => modalRefEditYourCosts.current?.open()}
      />

      <OtherConsiderations />
    </FormikProvider>
  );
};

const EditMaximumVehiclePaymentSection: ComponentType<{
  onApply?: () => void;
}> = function EditMaximumVehiclePaymentSection({ onApply }) {
  const { t } = useTranslation();
  const handleConfirm = useCallback(async () => {
    onApply?.();
  }, [onApply]);

  return (
    <FormSectionGroup id="toggle-payment">
      <FormSection title={t('pages.results.form1_title')}>
        <MaximumVehiclePaymentField />
        <br />
        <Button onClick={handleConfirm}>{t('shared.confirm')}</Button>
      </FormSection>
    </FormSectionGroup>
  );
};

const OtherConsiderations: ComponentType = function OtherConsiderations() {
  const track = useTracking();
  const { t } = useTranslation();

  const configuration = useFetchConfigurationQuery();
  const { data: { localisation } = {} } = configuration;

  // TODO: get from backend (AEM)
  const otherConsiderations: {
    title?: string;
    description?: string;
    link?: {
      label?: string;
      url?: string;
    };
  }[] = useRef(localisation?.otherConsiderations?.items ?? []).current;

  const trackLinkClick = useCallback(
    (link: string) =>
      track({
        type: 'exit',
        trackingStateUpdate: {
          eventInfo: [
            {
              eventTargetURL: link.replace(/^https?:\/\//, ''),
              linkInformation: 'Go to marketing website - section electric car',
            },
          ],
        },
        cleanVehicle: true,
      }),
    [track],
  );

  if (otherConsiderations.length === 0) {
    return null;
  }

  return (
    <>
      <HorizontalRule />
      <Heading level={2} title={t('shared.other_considerations')} />
      <div className={styles.otherContainer}>
        {otherConsiderations.map((current) => (
          <div
            className={styles.otherConsiderationItem}
            key={JSON.stringify(current)}>
            <FormHeading
              title={current.title}
              description={current.description}
            />
            {current.link && (
              <Button
                link
                small
                icon="semantic-forward"
                iconReversed
                onClick={() => {
                  trackLinkClick(current.link?.url ?? '');
                  window.open(
                    current.link?.url,
                    '_blank',
                    'noopener,noreferrer',
                  );
                }}>
                {current.link.label}
              </Button>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default ResultsScreen;
