import { FormSection as BronsonFormSection } from '@vwfs-bronson/bronson-react';
import { ComponentType, ReactNode } from 'react';

import styles from '@/components/atoms/FormSection.module.scss';

type Props = {
  title?: ReactNode;
  subtitle?: string;
  defaultOpen?: boolean;
  children?: ReactNode;
  disabled?: boolean;
  succes?: boolean;
  error?: boolean;
  onChange?: () => void;
  onClick?: () => void;
};

const FormSection: ComponentType<Props> = function FormSection(props) {
  return <BronsonFormSection className={styles.formSection} {...props} />;
};

export default FormSection;
