import { useEffect } from 'react';

import { useTrackingContext } from '@/providers/TrackingProvider';
import styles from '@/screens/NotFoundScreen.module.scss';

const NotFoundScreen = function NotFoundScreen() {
  const [, track] = useTrackingContext();

  useEffect(() => {
    track({
      type: 'view',
      trackingStateUpdate: {
        core: {
          attributes: {
            viewChange: 'Page Not Found',
          },
          pageInfo: {
            pageName: 'Error page',
          },
        },
        eventInfo: [
          {},
          {
            eventType: 'Error',
            eventAction: 'Page not found',
          },
        ],
        error: {
          errorCode: '404',
          errorMessage: 'Not found',
          errorCausingURL: window.location.href,
        },
      },
    });
  }, [track]);

  return (
    <div className={styles.container}>
      <span className="c-hero-teaser__title-row">404 - page not found</span>
    </div>
  );
};

export default NotFoundScreen;
