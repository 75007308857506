import { Table as BronsonTable } from '@vwfs-bronson/bronson-react';
import { ComponentType, ReactNode } from 'react';

export type Dependency = {
  name: string;
  version: string;
  copyright: string;
};

type Props = {
  dependencies: Dependency[];
};

const DependecyTable: ComponentType<Props> = function DependecyTable({
  dependencies,
}) {
  return (
    <BronsonTable>
      <BronsonTable.Thead>
        <BronsonTable.Tr>
          <BronsonTable.Th>name</BronsonTable.Th>
          <BronsonTable.Th>version</BronsonTable.Th>
          <BronsonTable.Th>copyright</BronsonTable.Th>
        </BronsonTable.Tr>
      </BronsonTable.Thead>
      <BronsonTable.Tbody>
        {dependencies.map((dependency) => (
          <BronsonTable.Tr key={dependency.name} role="row">
            <BronsonTable.Th>{dependency.name}</BronsonTable.Th>
            <BronsonTable.Th>{dependency.version}</BronsonTable.Th>
            <BronsonTable.Th>{dependency.copyright}</BronsonTable.Th>
          </BronsonTable.Tr>
        ))}
      </BronsonTable.Tbody>
    </BronsonTable>
  );
};

export default DependecyTable;
