import Wizard from '@/components/molecules/Wizard';
import styles from '@/components/organisms/QuestionnaireStepper.module.scss';
import { useQuestionnaireContext } from '@/providers/QuestionnaireContextProvider';

const QuestionnaireStepper = function QuestionnaireStepper({
  current: currentIndex,
  steps: labels,
  onStepClick,
}: {
  current: number;
  steps: string[];
  onStepClick?: (index: number) => void;
}) {
  const [state] = useQuestionnaireContext();
  const collapsedCounter = [currentIndex + 1, labels.length].join('/');
  const collapsedText = labels?.[currentIndex] || '';
  const steps = labels.map((label, key) => ({
    key,
    label,
    active: key === currentIndex,
    done: key < state.enabledStep,
  }));

  return (
    <div className={styles.container}>
      <Wizard
        collapsedCounter={collapsedCounter}
        collapsedText={collapsedText}
        onStepClick={onStepClick}
        steps={steps}
      />
    </div>
  );
};

export default QuestionnaireStepper;
