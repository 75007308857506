import { ComponentType, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from '@/components/organisms/VehicleCostComparison.module.scss';
import Heading from '@/components/atoms/Heading';
import Button from '@/components/atoms/Button';
import FormHeading from '@/components/atoms/FormHeading';
import StackedBarMolecule from '@/components/molecules/StackedBarMolecule';
import { useFormatCurrency } from '@/utils/translation';
import {
  MonthlyExpenseCategories,
  MonthlyExpenseCategory,
  Vehicle,
  VehicleType,
  VehicleTypes,
  useFetchConfigurationQuery,
} from '@/services/fes';
import { useTracking } from '@/providers/TrackingProvider';

type Props = {
  vehicles: Record<VehicleType, Vehicle | undefined>;
  onEditCostsClick?: () => void;
};

const VehicleCardComparison: ComponentType<Props> =
  function VehicleCardComparison({
    vehicles: vehiclesByType,
    onEditCostsClick,
  }) {
    const track = useTracking();
    const { t } = useTranslation();
    const formatCurrency = useFormatCurrency();
    const configuration = useFetchConfigurationQuery();

    const vehicles = useMemo(
      () =>
        VehicleTypes.map((type) => vehiclesByType[type]).filter(
          Boolean,
        ) as Vehicle[],
      [vehiclesByType],
    );

    const max = Object.fromEntries(
      MonthlyExpenseCategories.map(
        (category) =>
          [
            category,
            vehicles.reduce(
              (previous, current) =>
                Math.max(previous, current.costs.monthly[category]),
              0,
            ),
          ] as const,
      ),
    ) as Record<MonthlyExpenseCategory, number>;

    const handleEditCostsClick = useCallback(() => {
      track({
        type: 'interaction',
        trackingStateUpdate: {
          core: {
            attributes: {
              viewChange: 'Edit costs',
            },
          },
        },
        cleanVehicle: true,
        cleanFilter: true,
      });
      onEditCostsClick?.();
    }, [onEditCostsClick, track]);

    return (
      <>
        <Heading
          className={styles.heading}
          title={`${t('pages.results.heading3')}`}
          level={2}
        />
        <div className={styles.editButton}>
          {onEditCostsClick && (
            <Button onClick={handleEditCostsClick} secondary>
              {t('shared.edit_your_costs')}
            </Button>
          )}
        </div>
        {MonthlyExpenseCategories.map((category, i) => (
          <div key={category} className={styles.category}>
            <FormHeading
              title={t(`shared.categories.${category}.title`)}
              tooltip={configuration.data?.localisation.results[category]}
            />
            {vehicles.map((vehicle) => (
              <div key={vehicle.type} className={styles.stackedBar}>
                <StackedBarMolecule
                  values={[
                    {
                      color: `var(--stacked-bar-item-${category}-color)`,
                      value: vehicle.costs.monthly[category],
                      label: `${t(
                        `shared.vehicles.manufacturers.${vehicle.manufacturer}`,
                      )} ${vehicle.name}`,
                    },
                  ]}
                  label={`${t(`shared.vehicles.types.${vehicle.type}`)}`}
                  max={max[category]}
                  showTotal
                  formatValue={formatCurrency}
                  description={(() => {
                    switch (category) {
                      case 'vehiclePayment':
                        return `${t('shared.vehicle_payment_compliance', {
                          deposit: formatCurrency(vehicle.costs.deposit),
                          final_payment: formatCurrency(
                            vehicle.costs.finalPayment,
                          ),
                          apr: vehicle.costs.apr.value,
                        })}`;
                      default:
                        return '';
                    }
                  })()}
                />
              </div>
            ))}
          </div>
        ))}
      </>
    );
  };

export default VehicleCardComparison;
