import { ComponentType, useCallback } from 'react';
import {
  Header as BronsonHeader,
  Logo as BronsonLogo,
} from '@vwfs-bronson/bronson-react';
import { useNavigate } from 'react-router-dom';

import { useFetchConfigurationQuery } from '@/services/fes/configuration';
import styles from '@/components/organisms/Header.module.scss';

type HeaderProps = Record<string, unknown>;

const Logo = function Logo() {
  const navigate = useNavigate();
  const configuration = useFetchConfigurationQuery();

  const handleClick = useCallback(() => {
    if (configuration.data?.headerLogoLink) {
      window.location.href = configuration.data?.headerLogoLink;
    } else {
      navigate('/');
    }
  }, [configuration.data?.headerLogoLink, navigate]);
  return (
    <BronsonLogo
      ariallabel="Logo"
      onClick={handleClick}
      tabIndex={0}
      title="VWFS TCO"
    />
  );
};

const Header: ComponentType<HeaderProps> = function Header() {
  return (
    <div className={styles.container}>
      <BronsonHeader logo={<Logo />} staticHeader />
    </div>
  );
};

export default Header;
