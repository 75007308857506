import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Paragraph from '@/components/atoms/Paragraph';
import IconGrid from '@/components/molecules/IconGrid';
import MediaTeaserSection from '@/components/organisms/MediaTeaserSection';
import { useFetchConfigurationQuery } from '@/services/fes';
import LoadableView, {
  LoadableViewElement,
} from '@/components/templates/LoadableView';
import { useTrackingContext } from '@/providers/TrackingProvider';
import FormHeading from '@/components/atoms/FormHeading';
import styles from '@/screens/HomeScreen.module.scss';

const HomeScreen = function HomeScreen() {
  const navigate = useNavigate();
  const handleButtonClick = useCallback(
    () => navigate('/questionnaire'),
    [navigate],
  );

  const configuration = useFetchConfigurationQuery();
  const {
    data: { localisation } = {},
    error,
    isLoading,
    isFetching,
  } = configuration;
  const containerRef = useRef<LoadableViewElement>(null);

  useEffect(() => {
    if (error) {
      navigate('/500');
    }
  }, [error, navigate]);

  const [trackingState, track] = useTrackingContext();

  useEffect(() => {
    track({
      type: 'view',
      trackingStateUpdate: {
        core: {
          attributes: {
            viewChange: 'Introduction',
          },
          pageInfo: {
            pageName: 'Introduction',
          },
        },
      },
      cleanFilter: true,
    });
  }, [track, trackingState?.filter]);

  return (
    <LoadableView
      ref={containerRef}
      isLoading={isLoading}
      isFetching={isFetching}>
      {!isLoading && (
        <MediaTeaserSection
          buttonLabel={localisation?.pages?.landing?.button}
          fill
          imgProps={{ src: '/static/images/home_teaser.png', sizes: '100vw' }}
          onButtonClick={handleButtonClick}
          reversed
          title={localisation?.pages?.landing?.header1}>
          <Paragraph>{localisation?.pages?.landing?.section?.header}</Paragraph>
          <FormHeading
            title={localisation?.pages?.landing?.header2}
            tooltip={
              <div className={styles.tooltipContainer}>
                {localisation?.pages?.landing?.options.map((option) => (
                  <div key={option.content}>
                    <p>{option.content}</p>
                    <small>{option.description}</small>
                  </div>
                ))}
              </div>
            }
          />
          <IconGrid
            items={
              localisation?.pages?.landing?.options.map((option) => ({
                icon: 'checkmark',
                content: option.content,
              })) ?? []
            }
          />
          <Paragraph>{localisation?.pages?.landing?.section?.footer}</Paragraph>
        </MediaTeaserSection>
      )}
    </LoadableView>
  );
};

export default HomeScreen;
