import {
  createContext,
  Dispatch,
  PropsWithChildren,
  Reducer,
  useContext,
  useReducer,
} from 'react';

import { BodyStyleOrUnknown, VehicleType } from '@/services/fes/types';

export type QuestionnaireContextState = {
  fields: {
    annualMileage: number;
    bodyStyles?: BodyStyleOrUnknown[];
    contractLength?: number;
    costOfDiesel: number;
    costOfElectricity: number;
    costOfPetrol: number;
    isSubmitted: boolean;
    maximumVehiclePayment?: number;
  };
  enabledStep: number;
  preferredVehicle: Record<VehicleType, string | null>;
};

export type QuestionnaireContextAction =
  | {
      type: 'SET_FIELDS';
      fields: Partial<QuestionnaireContextState['fields']>;
    }
  | {
      type: 'SET_PREFERRED_VEHICLE';
      vehicleType: VehicleType;
      orderKey: string;
    }
  | {
      type: 'COMPLETE_STEP';
      value: number;
    };

const initialState: QuestionnaireContextState = {
  fields: {
    annualMileage: 10,
    bodyStyles: undefined,
    contractLength: undefined,
    costOfDiesel: 0,
    costOfElectricity: 0,
    costOfPetrol: 0,
    isSubmitted: false,
    maximumVehiclePayment: undefined,
  },
  enabledStep: 0,
  preferredVehicle: {
    diesel: null,
    electric: null,
    hybrid: null,
    petrol: null,
  },
};

const reducer: Reducer<QuestionnaireContextState, QuestionnaireContextAction> =
  function QuestionnaireReducer(state, action) {
    // eslint-disable-next-line default-case
    switch (action.type) {
      case 'SET_FIELDS':
        return {
          ...state,
          fields: {
            ...state.fields,
            ...action.fields,
          },
        };
      case 'SET_PREFERRED_VEHICLE':
        return {
          ...state,
          preferredVehicle: {
            ...state.preferredVehicle,
            [action.vehicleType]: action.orderKey,
          },
        };
      case 'COMPLETE_STEP':
        return {
          ...state,
          enabledStep: action.value,
        };
    }
  };

const QuestionnaireContext = createContext<
  [QuestionnaireContextState, Dispatch<QuestionnaireContextAction>] | null
>(null);

const QuestionnaireProvider = function ReducerProvider({
  children,
}: PropsWithChildren) {
  const value = useReducer(reducer, initialState);
  return (
    <QuestionnaireContext.Provider value={value}>
      {children}
    </QuestionnaireContext.Provider>
  );
};

export const useQuestionnaireContext = function useQuestionnaire() {
  const match = useContext(QuestionnaireContext);
  if (!match)
    throw new Error(
      'useQuestionnaireContext must be used within a QuestionnaireProvider',
    );
  return match;
};

export default QuestionnaireProvider;
