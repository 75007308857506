import { ComponentPropsWithoutRef, ReactNode } from 'react';

import FormField from '@/components/atoms/FormField';
import Select from '@/components/atoms/Select';
import styles from '@/components/molecules/NumberInput.module.scss';

type Props<T> = Pick<
  ComponentPropsWithoutRef<typeof Select<T>>,
  'onChange' | 'defaultValue' | 'items'
> & {
  error?: ReactNode;
  id?: string;
  labelText?: string;
  tooltip?: ReactNode;
  hint?: ReactNode;
};

const NumberSelect = function NumberSelect<T>({
  items,
  onChange,
  defaultValue,
  ...props
}: Props<T>) {
  return (
    <div className={styles.select}>
      <FormField {...props} type="select">
        <Select items={items} onChange={onChange} defaultValue={defaultValue} />
      </FormField>
    </div>
  );
};

export default NumberSelect;
