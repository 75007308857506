import { api } from '@/services/fes/api';
import { MonthlyExpenseCategory } from '@/services/fes/types';

type FetchConfigurationResponse = {
  configuration: {
    configuration: {
      averages: Record<
        'diesel' | 'electricity' | 'petrol',
        {
          value: number;
          unit: Record<'short' | 'long', string>;
          date: string;
          hint: string;
        }
      > & {
        mileage: number;
      };
      domain: string;
      localisation: {
        form: Record<
          | 'costOfDiesel'
          | 'costOfElectricity'
          | 'costOfPetrol'
          | 'maximumVehiclePayment',
          string
        >;
        results: Record<
          MonthlyExpenseCategory | 'vehiclesTooltip' | 'vehiclesInformation',
          string
        > & {
          description: {
            paragraph: string;
          }[];
        };
        otherConsiderations?: {
          title?: string;
          items?: {
            title?: string;
            description?: string;
            link?: {
              label?: string;
              url?: string;
            };
          }[];
        };
        pages: {
          landing: {
            title: string;
            header1: string;
            header2: string;
            options: {
              content: string;
              description: string;
            }[];
            section: {
              header: string;
              footer: string;
            };
            button: string;
          };
          journey: {
            title: string;
            financeBudget: {
              header: string;
              paymentHeader: string;
              paymentBody: string;
              paymentTooltip: string;
              contractHeader: string;
              contractBody: string;
              minValue: number;
              maxValue: number;
            };
            annualMileage: {
              header: string;
              tooltip: string;
              hint: string;
            };
            fuelCosts: {
              header: string;
              inputHeader: string;
              inputBody: string;
            };
            bodyStyle: {
              header: string;
              inputBody: string;
            };
          };
          review: {
            description: {
              paragraph: string;
            }[];
            totalByFuelHeader: string;
            disclaimer: string;
          };
        };
        disclaimer: {
          title: string;
          sections: {
            header: string;
            text: string;
          }[];
        };
        copyright: string;
      };
      headerLogoLink: string;
    };
  };
};

export type Configuration = Omit<
  FetchConfigurationResponse['configuration']['configuration'],
  'averages'
> & {
  averages?: Record<
    'diesel' | 'electricity' | 'petrol',
    {
      value: number;
      unit: Record<'short' | 'long', string>;
      date: Date;
      hint: string;
    }
  > & {
    mileage: number;
  };
};

const configurationApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchConfiguration: build.query<Configuration, void>({
      query: () => 'configuration',
      providesTags: ['Configuration'],
      transformResponse: (res: FetchConfigurationResponse) => ({
        ...res.configuration.configuration,
        averages: {
          ...res.configuration.configuration.averages,
          diesel: {
            ...res.configuration.configuration.averages.diesel,
            date: new Date(
              res.configuration.configuration.averages.diesel.date,
            ),
          },
          electricity: {
            ...res.configuration.configuration.averages.electricity,
            date: new Date(
              res.configuration.configuration.averages.electricity.date,
            ),
          },
          petrol: {
            ...res.configuration.configuration.averages.petrol,
            date: new Date(
              res.configuration.configuration.averages.petrol.date,
            ),
          },
        },
      }),
    }),
  }),
});

export const { useFetchConfigurationQuery } = configurationApi;
