import { ComponentType } from 'react';
import { IconList as BronsonIconList } from '@vwfs-bronson/bronson-react';

type Props = {
  items: {
    icon: string;
    content: string;
  }[];
};

const IconList: ComponentType<Props> = function IconList({ items }) {
  return (
    <BronsonIconList>
      {items.map(({ icon, content }) => (
        <BronsonIconList.Item key={content} icon={icon} children={content} />
      ))}
    </BronsonIconList>
  );
};

export default IconList;
