import { ComponentType } from 'react';
import { useTranslation } from 'react-i18next';

import StackedBarMolecule from '@/components/molecules/StackedBarMolecule';
import { MonthlyExpenseCategories, Vehicle } from '@/services/fes';
import { useFormatCurrency } from '@/utils/translation';

type Props = {
  description?: string;
  max?: number;
  title?: string;
  vehicle: Vehicle;
};

const CostSummary: ComponentType<Props> = function CostSummary({
  description,
  max: maxInput,
  title,
  vehicle,
}) {
  const { t } = useTranslation();
  const formatCurrency = useFormatCurrency();
  const max = maxInput || vehicle.costs.totalMonthlyPayment;

  return (
    <StackedBarMolecule
      max={max}
      values={MonthlyExpenseCategories.map((category) => ({
        label: `${t(`shared.categories.${category}.title`)}`,
        value: vehicle.costs.monthly[category] || 0,
        color: `var(--stacked-bar-item-${category}-color)`,
        displayValue: category === 'vehiclePayment',
      })).filter((current) => current.value > 0)}
      description={description}
      showTotal
      formatValue={(v) => formatCurrency(v)}
      title={title}
    />
  );
};

export default CostSummary;
