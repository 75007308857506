export const bronsonThemes = [
  'audi',
  'bluelabel',
  'generic',
  'seat',
  'skoda',
  'vw6',
] as const;
export type BronsonTheme = typeof bronsonThemes[number];

export const brands = [
  'audi',
  'bluelabel',
  'default',
  'seat',
  'skoda',
  'vw',
] as const;
export type Brand = typeof brands[number];

export const bronsonThemeToBrand: Record<BronsonTheme, Brand> = {
  audi: 'audi',
  bluelabel: 'bluelabel',
  generic: 'default',
  seat: 'seat',
  skoda: 'skoda',
  vw6: 'vw',
};
