import {
  ClassAttributes,
  ComponentType,
  InputHTMLAttributes,
  ReactNode,
  useMemo,
} from 'react';
import { FieldHookConfig, useField } from 'formik';

import Button from '@/components/atoms/Button';
import FormField from '@/components/atoms/FormField';
import Input from '@/components/atoms/Input';
import styles from '@/components/molecules/NumberInput.module.scss';

const NumberInput: ComponentType<
  InputHTMLAttributes<HTMLInputElement> &
    ClassAttributes<HTMLInputElement> &
    FieldHookConfig<string> & {
      label?: string;
      tooltip?: ReactNode;
      hint?: ReactNode;
      minValue?: number;
      maxValue?: number;
      stepAmount?: number;
      forceShowError?: boolean;
      addonText?: string;
      reversed?: boolean;
    }
> = function NumberInput({
  hint,
  label,
  maxValue,
  minValue,
  stepAmount,
  tooltip,
  placeholder,
  forceShowError,
  addonText,
  reversed,
  ...props
}) {
  const [field, { touched, error }, { setValue }] = useField(props);

  const stepperConfig = useMemo(() => {
    if (!stepAmount) return null;
    const value = parseFloat(field.value) || minValue || 0;
    return {
      onPlusClick: () => {
        const next = value + stepAmount;
        setValue(
          `${Math.min(
            Math.max(next, minValue === undefined ? next : minValue),
            maxValue === undefined ? next : maxValue,
          ).toFixed(0)}`,
        );
      },
      onMinusClick: () => {
        const next = value - stepAmount;
        setValue(
          `${Math.min(
            Math.max(next, minValue === undefined ? next : minValue),
            maxValue === undefined ? next : maxValue,
          ).toFixed(0)}`,
        );
      },
    };
  }, [field.value, maxValue, minValue, setValue, stepAmount]);

  return (
    <FormField
      error={(touched || forceShowError) && error}
      hint={hint}
      id={props.id}
      labelText={label}
      tooltip={tooltip}
      type="input">
      <div className={styles.inputGroup}>
        <Input
          error={Boolean((touched || forceShowError) && error?.length)}
          placeholder={placeholder}
          {...field}
          addonText={addonText}
          reversed={reversed}
        />
        {stepperConfig && (
          <>
            <Button
              id={props.id ? `${props.id}-button-minus` : undefined}
              icon="minus"
              onClick={stepperConfig.onMinusClick}
            />
            <Button
              id={props.id ? `${props.id}-button-plus` : undefined}
              icon="plus"
              onClick={stepperConfig.onPlusClick}
            />
          </>
        )}
      </div>
    </FormField>
  );
};

export default NumberInput;
