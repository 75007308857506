import {
  Routes,
  Route,
  Navigate,
  MemoryRouter,
  useNavigate,
} from 'react-router-dom';
import { Fragment, PropsWithChildren, useEffect } from 'react';

import BaseTemplate from '@/components/templates/BaseTemplate';
import QuestionnaireTemplate from '@/components/templates/QuestionnaireTemplate';
import HomeScreen from '@/screens/HomeScreen';
import NotFoundScreen from '@/screens/NotFoundScreen';
import Questionnaire from '@/screens/Questionnaire';
import ResultsScreen from '@/screens/ResultsScreen';
import JourneyTemplate from '@/components/templates/JourneyTemplate';
import CostReviewScreen from '@/screens/CostReviewScreen';
import ServerErrorScreen from '@/screens/ServerErrorScreen';
import ThirdPartyPolicyScreen from '@/screens/ThirdPartyPolicyScreen';

function RouterProvider() {
  return (
    <MemoryRouter>
      <InitialRouteGuard>
        <Routes>
          <Route element={<BaseTemplate />} path="">
            <Route element={<JourneyTemplate />} path="">
              <Route element={<HomeScreen />} path="" />
              <Route element={<QuestionnaireTemplate />} path="/questionnaire">
                <Route element={<Navigate to="budget" />} path="" />
                <Route element={<Questionnaire.BudgetScreen />} path="budget" />
                <Route
                  element={<Questionnaire.MileageScreen />}
                  path="mileage"
                />
                <Route element={<Questionnaire.CostsScreen />} path="costs" />
                <Route element={<Questionnaire.StyleScreen />} path="style" />
              </Route>
              <Route element={<CostReviewScreen />} path="/cost_review" />
              <Route element={<ResultsScreen />} path="/results" />
            </Route>
            <Route
              element={<ThirdPartyPolicyScreen />}
              path="/third-party-licenses"
            />
            <Route element={<ServerErrorScreen />} path="500" />
            <Route element={<NotFoundScreen />} path="*" />
          </Route>
        </Routes>
      </InitialRouteGuard>
    </MemoryRouter>
  );
}

function InitialRouteGuard({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  useEffect(() => {
    if (window.location.pathname === '/') {
      return;
    }
    navigate('/404');
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <Fragment children={children} />;
}

export default RouterProvider;
