import { ComponentType, useRef } from 'react';
import { t } from 'i18next';
import { useField } from 'formik';

import RadioButtonGroup from '@/components/molecules/RadioButtonGroup';

const ContractLengthField: ComponentType = function ContractLengthField() {
  const [{ value }, { error }, { setValue }] = useField('contractLength');
  const [{ value: isSubmitted }] = useField('isSubmitted');

  const items = useRef(
    ['18', '24', '36', '48'].map((key) => ({
      key,
      label: (
        <>
          {key}
          <br />
          {t('shared.months')}
        </>
      ),
    })),
  ).current;

  return (
    <RadioButtonGroup
      items={items}
      onChange={(e: { target: { value: string } }) => setValue(e.target.value)}
      value={value}
      errorMessage={isSubmitted ? error : undefined}
    />
  );
};

export default ContractLengthField;
